<template>
  <div id="Mhome">
    <mtabbarVue />
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img class="img" :src="image" />
      </van-swipe-item>
    </van-swipe>
    <div class="title fw6 hui353 tac">核心产品与服务</div>
    <div class="box flex JCspaceBetween fwW">
      <div
        class="box-item flex ALcenter JCcenter white fw5"
        v-for="(item, index) in hexin"
        :key="index"
        :style="{ backgroundImage: 'url(' + item.bg + ')' }"
      >
        <img :src="item.icon" class="box-item-icon" alt="" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <div class="title fw6 hui353 tac">解决方案</div>
    <div class="box">
      <div
        class="box-li flex ALcenter JCspaceBetween"
        v-for="(item, index) in fangan"
        :key="index"
      >
        <img :src="item.icon" class="box-li-icon" alt="" />
        <div class="box-li-rig">
          <div class="box-li-rig-tit">{{ item.name }}</div>
          <div class="box-li-rig-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="more flex ALcenter JCcenter">
      <div>更多</div>
      <img class="more-icon" src="../assets/m/more1.png" alt="" />
    </div>

    <ManliVue />
    <Mabout />
    <MnewsVue />

    <MbottomVue />

  </div>
</template>

<script>
import { images, hexin, fangan } from "@/store/mdata.js";
import ManliVue from "../components/mcom/Manli.vue";
import Mabout from "../components/mcom/Mabout.vue";
import MnewsVue from '../components/mcom/Mnews.vue';

import mtabbarVue from "@/components/Mtabbar.vue";
import MbottomVue from "@/components/mcom/Mbottom.vue";

export default {
  name: "Mhome",
  components: {
    ManliVue,
    Mabout,
    MnewsVue,

    mtabbarVue,
    MbottomVue,
    
  },
  data() {
    return {
      images: images,
      hexin: hexin,
      fangan: fangan,
    };
  },
};
</script>

<style lang="less" scoped>
#Mhome {
  width: 750px;
  .img {
    width: 750px;
    height: 620px;
  }

  .title {
    width: 750px;
    height: 126px;
    line-height: 126px;
    font-size: 36px;
  }

  .box {
    width: 702px;
    margin: 0 24px;

    .box-item {
      width: 340px;
      height: 104px;
      margin-bottom: 22px;
      background-size: 100% 100%;
      font-size: 30px;
      .box-item-icon {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
    }

    .box-li {
      width: 702px;
      height: 200px;
      background: linear-gradient(270deg, #f3faff 0%, #dff2ff 100%);
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 24px;

      .box-li-icon {
        width: 136px;
        height: 128px;
        margin-left: 16px;
      }

      .box-li-rig {
        width: 520px;
        margin-right: 14px;
        .box-li-rig-tit {
          font-weight: 500;
          font-size: 31px;
          color: #353c58;
          line-height: 38px;
          margin-bottom: 12px;
        }

        .box-li-rig-text {
          font-weight: 400;
          font-size: 24px;
          color: #64698b;
          line-height: 38px;
        }
      }
    }

    .box-li:nth-child(2n) {
      background: linear-gradient(270deg, #f7faff 0%, #dfecff 100%);
    }

    .box-li:last-child {
      margin: 0;
    }
  }

  .more {
    width: 750px;
    height: 106px;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    .more-icon {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
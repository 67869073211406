import { render, staticRenderFns } from "./Habout.vue?vue&type=template&id=042f76ed&scoped=true"
import script from "./Habout.vue?vue&type=script&lang=js"
export * from "./Habout.vue?vue&type=script&lang=js"
import style0 from "./Habout.vue?vue&type=style&index=0&id=042f76ed&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042f76ed",
  null
  
)

export default component.exports
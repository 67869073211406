<template>
  <div id="Huoban">
    <div class="text flex ALcenter JCcenter">
      <div class="text-box">
        <div class="text-box-top">合作伙伴</div>
        <div class="text-box-bot">50+生态合作伙伴</div>
      </div>
    </div>
    <div class="imgbox">
      <vue-seamless-scroll
        class="scroll_box imgbox-li"
        :data="huoban"
        :class-option="defineScroll"
      >
        <img
          class="imgbox-li-icon"
          v-for="(item, index) in huoban"
          :key="index"
          :src="item.icon"
          alt=""
        />
      </vue-seamless-scroll>
      <vue-seamless-scroll
        class="scroll_box imgbox-li"
        :data="huoban2"
        :class-option="defineScroll2"
      >
        <img
          class="imgbox-li-icon"
          v-for="(item, index) in huoban2"
          :key="index"
          :src="item.icon"
          alt=""
        />
      </vue-seamless-scroll>
      <vue-seamless-scroll
        class="scroll_box imgbox-li"
        :data="huoban3"
        :class-option="defineScroll"
      >
        <img
          class="imgbox-li-icon"
          v-for="(item, index) in huoban3"
          :key="index"
          :src="item.icon"
          alt=""
        />
      </vue-seamless-scroll>

      <!-- <div class="imgbox-li flex JCcenter">
        <img
          class="imgbox-li-icon"
          v-for="(item, index) in huoban"
          :key="index"
          :src="item.icon"
          alt=""
        />
      </div>
      <div class="imgbox-li flex JCcenter">
        <img
          class="imgbox-li-icon"
          v-for="(item, index) in huoban2"
          :key="index"
          :src="item.icon"
          alt=""
        />
      </div>
      <div class="imgbox-li flex JCcenter">
        <img
          class="imgbox-li-icon"
          v-for="(item, index) in huoban3"
          :key="index"
          :src="item.icon"
          alt=""
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { huoban, huoban2, huoban3 } from "@/store/datat.js";

export default {
  name: "Huoban",
  data() {
    return {
      huoban,
      huoban2,
      huoban3,
    };
  },
  //定义计算属性，默认执行一次（监听，类似于data概念）；
  computed: {
    defineScroll() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 3, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    defineScroll2() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="less" scoped>
#Huoban {
  width: 1920px;
  .text {
    width: 1920px;
    height: 173px;
    .text-box {
      .text-box-top {
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
        margin-bottom: 17px;
      }
      .text-box-bot {
        font-weight: 400;
        font-size: 14px;
        color: #64698b;
      }
    }
  }
  .imgbox {
    width: 1920px;
    font-size: 0;

    .imgbox-li {
      width: 1920px;
      height: 60px;
      margin-bottom: 25px;
      overflow: hidden;
      .imgbox-li-icon {
        width: 206px;
        height: 60px;
        margin-right: 16px;
      }
    }
  }
}
</style>
<template>
  <div id="Mchangjing">
    <div class="tit tac">应用场景</div>
    <div class="imgbox flex JCspaceBetween">
      <img
        v-for="(item, index) in list"
        :key="index"
        :src="idx == item.id ? item.icon2 : item.icon1"
        :class="idx == item.id ? 'imgbox-da' : 'imgbox-xiao'"
        alt=""
      >
      <!-- <div class="imgbox-xiao"></div>
      <div class="imgbox-da"></div>
      <div class="imgbox-xiao2"></div> -->
    </div>
    <div class="tit2">{{ list[idx - 1].name }}</div>
    <div class="text textTwo3">{{ list[idx - 1].text }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: 1,
      list: [
        {
          id: 1,
          name: "新建社区一体化智慧建设",
          text: "新建社区一体化智慧建设，围绕社区安防、消防、人员管理、房屋管理、事件管理、出入管理等场景进行建设，增强社区生活氛围和居住体验感，赋能社区、物业数字化管理，提升社区治理和服务水平。",
          icon1: require("@/assets/bg/Hchangjing1.png"),
          icon2: require("@/assets/bg/Hchangjing11.png"),
        },
        {
          id: 2,
          name: "老旧小区智能化改造",
          text: "老旧小区智能化场景提升，围绕社区安防、消防、人员管理、房屋管理、事件管理、出入管理等场景进行建设，增强社区生活氛围和居住体验感，赋能社区、物业数字化管理，提升社区治理和服务水平。",
          icon1: require("@/assets/bg/Hchangjing22.png"),
          icon2: require("@/assets/bg/Hchangjing2.png"),
        },
        {
          id: 3,
          name: "街道/片区智能化提升",
          text: "街道/片区智能化提升，围绕社区安防、消防、人员管理、房屋管理、事件管理、出入管理等场景进行建设，增强社区生活氛围和居住体验感，赋能社区、物业数字化管理，提升社区治理和服务水平。",
          icon1: require("@/assets/bg/Hchangjing3.png"),
          icon2: require("@/assets/bg/Hchangjing33.png"),
        },
      ],
      anyTime: null,
    };
  },
  mounted() {
    this.anyTime = setInterval(this.upAny, 3000);
  },
  beforeDestroy() {
    // 清除定时器
    if (this.anyTime) {
      clearInterval(this.anyTime);
    }
  },
  methods: {
    upAny() {
      if (this.idx == 1) {
        this.idx = 2;
      } else if (this.idx == 2) {
        this.idx = 3;
      } else if (this.idx == 3) {
        this.idx = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#Mchangjing {
  width: 750px;
  height: 660px;
  background: #fff;

  .tit {
    width: 750px;
    height: 126px;
    line-height: 126px;
    font-weight: 600;
    font-size: 36px;
    color: #353c58;
  }

  .imgbox {
    width: 702px;
    height: 276px;
    margin: 0 24px;
    .imgbox-xiao {
      width: 106px;
      height: 276px;
    }

    .imgbox-da {
      width: 474px;
      height: 276px;
    }
  }

  .tit2 {
    width: 702px;
    margin: 0 24px;
    height: 90px;
    line-height: 90px;
    font-weight: 500;
    font-size: 30px;
    color: #353c58;
  }

  .text {
    width: 702px;
    margin: 0 24px;
    font-size: 24px;
    color: #64698b;
    line-height: 40px;
  }
}
</style>
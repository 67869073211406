<template>
  <div id="Hfangan">
    <div class="banner flex ALcenter JCcenter">
      <div class="banner-box">
        <div class="banner-box-title">智慧社区解决方案</div>
        <div class="banner-box-text">
          助力新建住宅、存量社区、老旧街道等社区智慧化管理，构建智慧社区一体化管理平台，轻松管理庞杂设备系统、能耗监测、安防监控、通行管控等场景，实现服务品质提升与管理降本增效
        </div>
        <div class="banner-box-btnbox flex">
          <div class="banner-box-btnbox-submit tac" @click="opendialogshow">
            申请试用
          </div>
          <div class="banner-box-btnbox-submit2 tac" @click="opendialogshow">
            预约演示
          </div>
        </div>
      </div>
    </div>
    <div class="tabbar">
      <div class="tabbar-box flex JCcenter">
        <div
          class="tabbar-box-box fs9 hand-cursor"
          @click="switchIdx(item.id)"
          :class="item.id == idx ? 'lan038 fw6' : 'hui646'"
          v-for="(item, index) in tabbarlist"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="texing">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">方案特性</div>
        <div class="jieshao-box-text tac">Scheme Characteristics</div>
      </div>
      <div class="texing-box flex JCspaceBetween">
        <div class="texing-box-li" v-for="(item, index) in texing" :key="index">
          <div class="texing-box-li-imgbox flex JCcenter">
            <img :src="item.icon" class="texing-box-li-imgbox-icon" alt="" />
          </div>
          <div class="texing-box-li-name tac">{{ item.name }}</div>
          <div class="texing-box-li-text tac">{{ item.text }}</div>
        </div>
      </div>
    </div>

    <div class="jiazhi">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">方案价值</div>
        <div class="jieshao-box-text tac">Value of The Plan</div>
      </div>
      <div class="jiazhi-box flex fwW JCspaceBetween">
        <div
          class="jiazhi-box-li flex ALcenter JCcenter"
          v-for="(item, index) in jiazhi"
          :key="index"
        >
          <div class="jiazhi-box-li-item flex ALcenter">
            <img :src="item.icon" class="jiazhi-box-li-item-icon" alt="" />
            <div>
              <div class="jiazhi-box-li-item-tit">{{ item.name }}</div>
              <div class="jiazhi-box-li-item-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nengli">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">方案能力</div>
        <div class="jieshao-box-text tac">Scheme Capability</div>
      </div>

      <div class="nengli-box flex JCspaceBetween">
        <div class="nengli-box-li margin01">
          <div class="nengli-box-li-tit">
            <div class="nengli-box-li-tit-name">智慧门禁</div>
            <img
              src="@/assets/icon/Hfangan/nl1.png"
              class="nengli-box-li-tit-poa"
              alt=""
            />
          </div>
          <div class="nengli-box-li-text">人脸识别 · 远程授权 · 远程对讲</div>
        </div>
        <div class="nengli-box-li margin02">
          <div class="nengli-box-li-tit">
            <div class="nengli-box-li-tit-name">智慧家庭</div>
            <img
              src="@/assets/icon/Hfangan/nl2.png"
              class="nengli-box-li-tit-poa"
              alt=""
            />
          </div>
          <div class="nengli-box-li-text">
            家庭安防监控 · 家庭环境控制 · 智能家居系统
          </div>
        </div>
      </div>

      <div class="nengli-box flex JCspaceBetween">
        <div class="nengli-box-li margin03">
          <div class="nengli-box-li-tit">
            <div class="nengli-box-li-tit-name">智慧能源</div>
            <img
              src="@/assets/icon/Hfangan/nl3.png"
              class="nengli-box-li-tit-poa"
              alt=""
            />
          </div>
          <div class="nengli-box-li-text">智能水表 · 智能电表 · 智能燃气表</div>
        </div>
        <div class="nengli-box-li margin04">
          <div class="nengli-box-li-tit">
            <div class="nengli-box-li-tit-name">智慧安防</div>
            <img
              src="@/assets/icon/Hfangan/nl4.png"
              class="nengli-box-li-tit-poa"
              alt=""
            />
          </div>
          <div class="nengli-box-li-text">
            死角监控 · 人脸抓拍识别 · 自动报警
          </div>
        </div>
      </div>

      <div class="nengli-box flex JCspaceBetween">
        <div class="nengli-box-li margin05">
          <div class="nengli-box-li-tit">
            <div class="nengli-box-li-tit-name">智慧环境</div>
            <img
              src="@/assets/icon/Hfangan/nl5.png"
              class="nengli-box-li-tit-poa"
              alt=""
            />
          </div>
          <div class="nengli-box-li-text">
            烟雾监测 · 温湿度监测 · PM2.5监测
          </div>
        </div>
        <div class="nengli-box-li margin06">
          <div class="nengli-box-li-tit">
            <div class="nengli-box-li-tit-name">智慧访客</div>
            <img
              src="@/assets/icon/Hfangan/nl6.png"
              class="nengli-box-li-tit-poa"
              alt=""
            />
          </div>
          <div class="nengli-box-li-text">访客申请 · 业主授权 · 远程授权</div>
        </div>
      </div>

      <div class="nengli-box flex JCspaceBetween">
        <div class="nengli-box-li margin07">
          <div class="nengli-box-li-tit">
            <div class="nengli-box-li-tit-name">智慧停车</div>
            <img
              src="@/assets/icon/Hfangan/nl1.png"
              class="nengli-box-li-tit-poa"
              alt=""
            />
          </div>
          <div class="nengli-box-li-text">
            进出口识别 · 车位远程查看 · 智能寻车
          </div>
        </div>
        <div class="nengli-box-li margin08">
          <div class="nengli-box-li-tit">
            <div class="nengli-box-li-tit-name">智慧消防</div>
            <img
              src="@/assets/icon/Hfangan/nl8.png"
              class="nengli-box-li-tit-poa"
              alt=""
            />
          </div>
          <div class="nengli-box-li-text">智能烟感 · 智能井盖 · 智能充电</div>
        </div>
      </div>
    </div>

    <HchangjingVue />

    <!-- <div class="changjing">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">应用场景</div>
        <div class="jieshao-box-text tac">Application Scenarios</div>
      </div>
      <div class="changjing-box flex JCspaceBetween">
        <div class="changjing-box-bg1">
          <div class="changjing-box-bg1-poa1"></div>
          <div class="changjing-box-bg1-poa2 tac white fs8 fw6">
            新建社区一体化智慧建设
          </div>
        </div>
        <div class="changjing-box-bg2">
          <div class="changjing-box-bg2-poa1"></div>
          <div class="changjing-box-bg2-poa2 white fs8 fw6">
            <div class="changjing-box-bg2-poa2-tit">老旧小区智能化建设</div>
            <div class="changjing-box-bg2-poa2-text">
              老旧小区智能化场景提升，围绕社区安防、消防、人员管理、房屋管理、事件管理、出入管理等场景进行建设，增强社区生活氛围和居住体验感，赋能社区、物业数字化管理，提升社区治理和服务水平。
            </div>
          </div>
        </div>
        <div class="changjing-box-bg3">
          <div class="changjing-box-bg3-poa1"></div>
          <div class="changjing-box-bg3-poa2 tac white fs8 fw6">
            街道/片区智能化提升
          </div>
        </div>
      </div>
    </div> -->

    <div class="anli">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">客户案例</div>
        <div class="jieshao-box-text tac">Customer Cases</div>
      </div>
      <div class="neirongbox flex JCspaceBetween fwW">
        <div
          class="neirongbox-li"
          v-for="(item, index) in jingdiananlilist[0].child"
          :key="index"
          v-show="index < 2"
        >
          <img class="neirongbox-li-img" :src="item.icon" alt="" />
          <div class="neirongbox-li-title fs10">{{ item.title }}</div>
          <div class="neirongbox-li-text textTwo3">{{ item.text }}</div>
          <div class="neirongbox-li-tagbox flex">
            <div
              class="neirongbox-li-tagbox-tag"
              v-for="(item2, index2) in item.tag"
              :key="index2"
            >
              {{ item2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jingdiananlilist } from "@/store/datat.js";
import HchangjingVue from '../components/Hchangjing.vue';

export default {
  name: "Hfangan",
  components: {
    HchangjingVue
  },
  data() {
    return {
      idx: 1,
      jingdiananlilist,
      tabbarlist: [
        {
          id: 1,
          name: "方案特性",
        },
        {
          id: 2,
          name: "方案价值",
        },
        {
          id: 3,
          name: "方案能力",
        },
        {
          id: 4,
          name: "应用场景",
        },
        {
          id: 5,
          name: "客户案例",
        },
      ],
      texing: [
        {
          icon: require("@/assets/icon/Hfangan/tx1.png"),
          name: "适用性强",
          text: "可广泛适用于新建住宅、存量社区、老旧街道，通过捂脸科技提供场景赋能",
        },
        {
          icon: require("@/assets/icon/Hfangan/tx2.png"),
          name: "设备类型广泛",
          text: "跨设备类型、协议、厂商、地域实现统一连接，实现互联互通、数据和业务统一",
        },
        {
          icon: require("@/assets/icon/Hfangan/tx3.png"),
          name: "重塑业务",
          text: "不仅是监测，而是将设备与设备数据作为新的生产函数，融合ai助力物业构建智慧社区管理系统平台",
        },
        {
          icon: require("@/assets/icon/Hfangan/tx4.png"),
          name: "复制性强",
          text: "助力集团客户形成标准，快速批量落地，复制成本低、实现集团标准化和管控",
        },
      ],
      jiazhi: [
        {
          name: "提升社区治理和服务水平",
          text: "社区人员管理、房屋管理、物业管理、车辆管理等实现智能化，通过手机端小程序、管理端后台、可视化驾驶舱大屏多端智能化管理和分析。",
          icon: require("@/assets/icon/Hfangan/jz1.png"),
        },
        {
          name: "增强社区生活氛围和体验感",
          text: "打造社区交通、教育、健康、医疗等场景应用，搭建老年活动中心、公共邻里空间、健身中心、生活市集、社区图书馆等，增强社区生活氛围和体验感。",
          icon: require("@/assets/icon/Hfangan/jz2.png"),
        },
        {
          name: "降低社区安全和消防风险问题",
          text: "实时监控、自动化运维。实时检测监控视频、消防烟感等iot设备运行状态、如遇故障或报警信息自动化发生报警通知，相关人员收到信息可及时处理，保障社区安全。",
          icon: require("@/assets/icon/Hfangan/jz3.png"),
        },
        {
          name: "促进邻里互动，和谐共处",
          text: "社区和物业通过平台进行综合管理、活动发布、问题反馈处置等拉动邻里居民互动互助，促进邻里和谐发展。营造良好社区生活氛围。",
          icon: require("@/assets/icon/Hfangan/jz4.png"),
        },
      ],
    };
  },
  methods: {
    switchIdx(e) {
      this.idx = e;
    },

    opendialogshow() {
      let ac = true;
      this.$store.commit("changedialogshow", ac);
    },
  },
};
</script>

<style lang="less" scoped>
#Hfangan {
  width: 1920px;
  .banner {
    width: 1920px;
    height: 500px;
    background: url(~@/assets/bg/Hfangan.png) no-repeat;
    background-size: 100% 100%;
    .banner-box {
      width: 1440px;
      margin: 0 240px;
      .banner-box-title {
        width: 1440px;
        height: 59px;
        font-weight: 600;
        font-size: 36px;
        color: #353c58;
        line-height: 59px;
        margin-bottom: 12px;
      }

      .banner-box-text {
        width: 580px;
        font-size: 16px;
        color: #64698b;
        line-height: 26px;
        margin-bottom: 36px;
      }

      .banner-box-btnbox {
        width: 580px;
        .banner-box-btnbox-submit {
          width: 160px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #ffffff;
          background: #038ef2;
          border-radius: 4px 4px 4px 4px;
          margin-right: 16px;
        }
        .banner-box-btnbox-submit2 {
          width: 160px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #038ef2;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #038ef2;
        }
      }
    }
  }

  .tabbar {
    width: 1920px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.25);
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 4px;

    .tabbar-box {
      width: 1440px;
      height: 80px;
      margin: 0 240px;

      .tabbar-box-box {
        height: 80px;
        line-height: 80px;
        margin-right: 230px;
      }
      .tabbar-box-box:last-child {
        margin-right: 0;
      }
    }
  }

  .texing {
    width: 1920px;
    height: 404px;
    background: #ffffff;
    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 19px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 47px;
        font-size: 14px;
        color: #64698b;
      }
    }

    .texing-box {
      width: 1440px;
      margin: 0 240px;
      .texing-box-li {
        width: 286px;
        .texing-box-li-imgbox {
          width: 286px;
          margin-bottom: 16px;
          .texing-box-li-imgbox-icon {
            width: 48px;
            height: 48px;
          }
        }

        .texing-box-li-name {
          width: 286px;
          height: 28px;
          line-height: 28px;
          font-weight: 600;
          font-size: 20px;
          color: #353c58;
          margin-bottom: 16px;
        }

        .texing-box-li-text {
          width: 286px;
          font-size: 14px;
          color: #64698b;
          line-height: 24px;
        }
      }
    }
  }

  .jiazhi {
    width: 1920px;
    height: 638px;
    background: #f2f9ff;

    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 17px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 43px;
        font-size: 14px;
        color: #64698b;
      }
    }

    .jiazhi-box {
      width: 1440px;
      margin: 0 240px;

      .jiazhi-box-li {
        width: 694px;
        height: 184px;
        background: linear-gradient(360deg, #ffffff 0%, #f2f9ff 100%);
        box-shadow: 0px 4px 8px 0px rgba(213, 221, 228, 0.35);
        border-radius: 8px 8px 8px 8px;
        margin-bottom: 50px;
        border: 2px solid #ffffff;

        .jiazhi-box-li-item {
          .jiazhi-box-li-item-icon {
            width: 96px;
            height: 96px;
            margin-right: 30px;
          }

          .jiazhi-box-li-item-tit {
            width: 468px;
            height: 43px;
            font-weight: 600;
            font-size: 16px;
            color: #353c58;
          }
          .jiazhi-box-li-item-text {
            width: 468px;
            font-size: 14px;
            color: #64698b;
            line-height: 24px;
          }
        }
      }
    }
  }

  .nengli {
    width: 1920px;
    height: 768px;
    background: url(~@/assets/bg/Hnengli.png) no-repeat;
    background-size: 100% 100%;

    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 17px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 50px;
        font-size: 14px;
        color: #64698b;
      }
    }

    .nengli-box {
      width: 1920px;
      height: 100px;
      margin-bottom: 42px;
      .nengli-box-li {
        width: 326px;
        height: 100px;
        background: #ffffff;
        box-shadow: 6px 6px 8px 0px rgba(189, 195, 208, 0.2);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #dfe8ee;
        .nengli-box-li-tit {
          position: relative;
          width: 326px;
          height: 28px;
          line-height: 28px;
          font-weight: 600;
          font-size: 16px;
          color: #353c58;
          margin-top: 20px;
          background: linear-gradient(
            90deg,
            rgba(3, 142, 242, 0.2) 0%,
            rgba(3, 142, 242, 0) 100%
          );
          .nengli-box-li-tit-name {
            margin-left: 57px;
          }

          .nengli-box-li-tit-poa {
            position: absolute;
            width: 36px;
            height: 36px;
            left: 12px;
            top: -4px;
          }
        }

        .nengli-box-li-text {
          width: 267px;
          margin: 10px 0 0 57px;
          font-size: 14px;
          color: #64698b;
          line-height: 16px;
        }
      }

      .margin01 {
        margin-left: 530px;
      }
      .margin02 {
        margin-right: 530px;
      }
      .margin03 {
        margin-left: 340px;
      }
      .margin04 {
        margin-right: 340px;
      }
      .margin05 {
        margin-left: 240px;
      }
      .margin06 {
        margin-right: 240px;
      }
      .margin07 {
        margin-left: 430px;
      }
      .margin08 {
        margin-right: 430px;
      }
    }
  }

  .changjing {
    width: 1920px;
    background: #f2f9ff;
    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 17px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 50px;
        font-size: 14px;
        color: #64698b;
      }
    }

    .changjing-box {
      width: 1440px;
      height: 516px;
      margin: 0 240px;
      .changjing-box-bg1 {
        width: 256px;
        height: 516px;
        background: rgba(0, 0, 0, 0.5);
        background: url(~@/assets/bg/Hchangjing1.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        .changjing-box-bg1-poa1 {
          width: 256px;
          height: 516px;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        .changjing-box-bg1-poa2 {
          width: 256px;
          height: 22px;
          position: absolute;
          left: 0;
          bottom: 52px;
          z-index: 2;
        }
      }
      .changjing-box-bg2 {
        width: 888px;
        height: 516px;
        background: rgba(0, 0, 0, 0.5);
        background: url(~@/assets/bg/Hchangjing2.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        .changjing-box-bg2-poa1 {
          width: 888px;
          height: 516px;
          background: url(~@/assets/bg/Hchangjing4.png) no-repeat;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        .changjing-box-bg2-poa2 {
          width: 808px;
          position: absolute;
          left: 40px;
          bottom: 52px;
          z-index: 2;
          .changjing-box-bg2-poa2-tit {
            font-weight: 600;
            font-size: 24px;
            color: #ffffff;
            margin-bottom: 24px;
          }

          .changjing-box-bg2-poa2-text {
            font-size: 14px;
            color: #ffffff;
            line-height: 28px;
          }
        }
      }
      .changjing-box-bg3 {
        width: 256px;
        height: 516px;
        background: rgba(0, 0, 0, 0.5);
        background: url(~@/assets/bg/Hchangjing3.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        .changjing-box-bg3-poa1 {
          width: 256px;
          height: 516px;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        .changjing-box-bg3-poa2 {
          width: 256px;
          height: 22px;
          position: absolute;
          left: 0;
          bottom: 52px;
          z-index: 2;
        }
      }
    }
  }

  .anli {
    width: 1920px;
    background: #f2f9ff;
    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 17px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 50px;
        font-size: 14px;
        color: #64698b;
      }
    }

    .neirongbox {
      width: 1440px;
      margin: 0 240px;
      .neirongbox-li {
        width: 688px;
        height: 631px;
        background: #ffffff;
        border: 1px solid #dfe8ee;
        margin-bottom: 41px;
        font-size: 0;

        .neirongbox-li-img {
          width: 688px;
          height: 386px;
        }

        .neirongbox-li-title {
          width: 636px;
          margin: 0 25px;
          height: 68px;
          line-height: 68px;
        }

        .neirongbox-li-text {
          width: 636px;
          margin: 0 25px;
          font-size: 14px;
          color: #64698b;
          line-height: 24px;
          height: 96px;
          margin-bottom: 16px;
        }

        .neirongbox-li-tagbox {
          margin: 0 24px;
          width: 412px;
          height: 24px;
          .neirongbox-li-tagbox-tag {
            padding: 0 16px;
            height: 24px;
            line-height: 24px;
            background: rgba(3, 142, 242, 0.1);
            font-size: 12px;
            color: #038ef2;
            margin-right: 10px;
          }

          .neirongbox-li-tagbox-tag:nth-child(2n) {
            padding: 0 16px;
            height: 24px;
            line-height: 24px;
            background: rgba(255, 114, 34, 0.1);
            font-size: 12px;
            color: #ff7222;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
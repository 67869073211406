<!-- 权威资质 -->
<template>
  <div id="Mzizhi">
    <div class="tit tac">权威资质</div>
    <div class="box flex JCspaceBetween fwW">
      <div class="box-li" v-for="(item, index) in zizhi" :key="index">
        <div class="box-li-imgbox flex JCcenter">
            <img :src="item.icon" :class="item.fang?'box-li-imgbox-fang':'box-li-imgbox-chang'" alt="">
        </div>
        <div class="box-li-text tac">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { zizhi } from "@/store/datat.js";

export default {
  data() {
    return {
      zizhi,
    };
  },
};
</script>

<style lang="less" scoped>
#Mzizhi {
  width: 750px;
  background: #ffffff;
  .tit {
    width: 750px;
    height: 124px;
    line-height: 124px;
    font-weight: 600;
    font-size: 36px;
    color: #353c58;
  }
  .box {
    width: 702px;
    margin: 0 24px;
    padding-bottom: 10px;
    .box-li {
      width: 338px;
      height: 332px;
      background: #ffffff;
      box-shadow: 8px 8px 12px 0px rgba(223, 232, 238, 0.3);
      border-radius: 16px;
      border: 2px solid #dfe8ee;
      margin-bottom: 32px;
      .box-li-imgbox {
        width: 306px;
        height: 222px;
        margin: 24px 12px 0;
        .box-li-imgbox-fang {
            width: 306px;
            height: 222px;
        }
        .box-li-imgbox-chang {
            height: 222px;
            width: 158px;
        }
      }

      .box-li-text {
        width: 334px;
        height: 84px;
        line-height: 84px;
        font-size: 24px;
        color: #353c58;
      }
    }
  }
}
</style>
<template>
  <div id="Manli">
    <mtabbarVue />
    <div class="banner flex ALcenter">
      <div class="banner-text">
        <div class="banner-text-tit">累计打造100+</div>
        <div class="banner-text-tit">经典案例</div>
        <div class="banner-text-text">
          Accumulated Over 100 Classic Cases Created
        </div>
      </div>
    </div>
    <div class="tab">
      <van-tabs v-model="active" @click="onClick" class="custom-tabs" title-active-color="#038EF2" title-inactive-color="#353C58">
        <van-tab
          v-for="(item, index) in jingdiananlilist"
          :title="item.title"
          :name="index"
          :key="index"
        >
          <div class="tab-box">
            <div class="tab-box-tiao"></div>
            <div
              class="box"
              v-for="(item2, index2) in jingdiananlilist[active].child"
              :key="index2"
            >
              <img class="box-icon" :src="item2.icon" alt="" />
              <div class="box-body">
                <div class="box-body-tit">{{ item2.title }}</div>
                <div class="box-li-text textTwo">{{ item2.text }}</div>
                <div class="box-li-tagbox flex">
                  <div
                    class="box-li-tagbox-tag"
                    v-for="(item3, index3) in item2.tag"
                    :key="index3"
                  >
                    {{ item3 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-box-tiao"></div>
          </div>
        </van-tab>
        <MbottomVue />
      </van-tabs>
    </div>
    <!-- <MbottomVue /> -->
  </div>
</template>

<script>
import { jingdiananlilist } from "@/store/datat.js";
import mtabbarVue from "@/components/Mtabbar.vue";
import MbottomVue from "@/components/mcom/Mbottom.vue";

export default {
  name: "Manli",
  components: {
    mtabbarVue,
    MbottomVue,
  },
  data() {
    return {
      active: 0,
      jingdiananlilist: jingdiananlilist,
    };
  },
  methods: {
    onClick(name) {
      // console.log(name, title);
      this.active = name;
    },
  },
};
</script>

<style lang="less" scoped>
#Manli {
  width: 750px;
  .banner {
    width: 750px;
    height: 340px;
    background: url(~@/assets/m/anli1.png) no-repeat;
    background-size: 100% 100%;

    .banner-text {
      width: 662px;
      margin-left: 44px;
      .banner-text-tit {
        width: 662px;
        height: 64px;
        line-height: 64px;
        font-weight: 600;
        font-size: 44px;
        color: #353c58;
      }
      .banner-text-text {
        width: 662px;
        height: 64px;
        line-height: 64px;
        font-size: 20px;
        color: #64698b;
      }
    }
  }

  .tab {
    width: 750px;
    background: #fff;

    .custom-tabs {
      height: 100px;
      /deep/
      .van-tabs__line {
        background-color: #038ef2 !important; /* 这里设置为红色，你可以改成你想要的颜色 */
      }
    }

    .tab-box {
      width: 750px;
      background: #f2f9ff;

      .tab-box-tiao {
        width: 750px;
        height: 30px;
      }

      .box {
        width: 698px;
        height: 648px;
        margin: 0 24px 24px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        border: 2px solid #dfe8ee;
        overflow: hidden;
        .box-icon {
          width: 698px;
          height: 392px;
        }

        .box-body {
          width: 638px;
          margin: 0 30px;
          .box-body-tit {
            width: 638px;
            height: 82px;
            line-height: 82px;
            font-weight: 500;
            font-size: 30px;
            color: #353c58;
          }

          .box-li-text {
            width: 638px;
            height: 84px;
            line-height: 42px;
            font-size: 26px;
            color: #64698b;
            margin-bottom: 12px;
          }

          .box-li-tagbox {
            width: 638px;
            height: 36px;
            .box-li-tagbox-tag {
              padding: 0 16px;
              height: 36px;
              line-height: 36px;
              background: rgba(3, 142, 242, 0.1);
              font-size: 20px;
              color: #038ef2;
              margin-right: 20px;
              border-radius: 4px;
            }

            .box-li-tagbox-tag:nth-child(2n) {
              padding: 0 16px;
              height: 36px;
              line-height: 36px;
              background: rgba(255, 114, 34, 0.1);
              font-size: 20px;
              color: #ff7222;
              margin-right: 20px;
              border-radius: 4px;
            }
          }
        }
      }
      .box:last-child {
        margin-bottom: 0;
      }
    }
  }

  .tiao {
    width: 750px;
    height: 80px;
    background: #000;
  }
}
</style>
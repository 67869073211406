export const chanpin = [
    {
        name: '智慧社区平台',
        child: [
            {
                name: '社区治理场景',
                child: [
                    {
                        name: '人员管理',
                    },
                    {
                        name: '房屋管理',
                    },
                    {
                        name: '车辆管理',
                    },
                    {
                        name: '物业、小区管理',
                    },
                ],
            },
            {
                name: '社区服务场景',
                child: [
                    {
                        name: '邻里互动',
                    },
                    {
                        name: '积分体系',
                    },
                    {
                        name: '社区一件事',
                    },
                    {
                        name: '报事报修',
                    },
                ],
            },
            {
                name: '社区安防建设',
                child: [
                    {
                        name: '智能门禁',
                    },
                    {
                        name: '安防监控',
                    },
                    {
                        name: '消防安全',
                    },
                    {
                        name: '车辆道闸',
                    },
                ],
            },
        ],
    },
    {
        name: '物联网平台',
        child: [
            {
                name: '设备接入',
                child: [
                    {
                        name: '支持主流IOT设备融合接入',
                    },
                ],
            },
            {
                name: '设备管理',
                child: [
                    {
                        name: '设备在线管理、自动化运维',
                    },
                ],
            },
            {
                name: '预警管理',
                child: [
                    {
                        name: '预警时间自动化精准推送， 快速处置闭环',
                    },
                ],
            },
            {
                name: '低代码开发',
                child: [
                    {
                        name: '低代码实现接入管理， 输出至多场景应用',
                    },
                ],
            },
            {
                name: '智能边缘计算',
                child: [
                    {
                        name: '云边协同、近场计算、 软硬一体',
                    },
                ],
            },
        ],
    },
    {
        name: '增值服务',
        child: [
            {
                name: '数字孪生',
                child: [
                    {
                        name: '实景建模、数据融合',
                    },
                ],
            },
            {
                name: '3D建模',
                child: [
                    {
                        name: '精准还原实景建模、 虚拟融合',
                    },
                ],
            },
            {
                name: '可视化引擎',
                child: [
                    {
                        name: '一键生成数据可视化大屏， 总览态势',
                    },
                ],
            },
            {
                name: '流程引擎',
                child: [
                    {
                        name: '各类IOT预警时间处置流程 智能化配置',
                    },
                ],
            },
        ],
    },
    {
        name: '其他服务',
        child: [
            {
                name: '智慧园区网络建设',
                child: [
                    {
                        name: '解决园区企业网络入户 一步到位',
                    },
                ],
            },
            {
                name: '工程造价评估',
                child: [
                    {
                        name: '智慧社区、智慧楼宇等 工程造价评估',
                    },
                ],
            },
            {
                name: '工程图纸设计',
                child: [
                    {
                        name: '智慧社区、智慧楼宇等 工程图纸设计',
                    },
                ],
            },
            {
                name: '工程维保',
                child: [
                    {
                        name: '主流IOT设备工程维保， 专业检修和更新',
                    },
                ],
            },
        ],
    },
]

export const hangyej = {
    name: '行业解决方案',
    child: [
        { name: '智慧社区' },
        { name: '基层治理' },
        { name: '智慧城市' },
        { name: '智慧安防' },
        { name: '智慧楼宇' },
        { name: '智慧物业' },
        { name: '智慧校园' },
        { name: '智慧城管' },
        { name: '智慧文旅' },
        // { name: '土地开发管理' },
        { name: '智慧医院' },
        { name: '数智征兵' },
        { name: '智慧场馆' },
        { name: '智慧公安' },
    ]
}

export const tongyongj = {
    name: '通用解决方案',
    child: [
        { name: 'ICT智能化设备集成' },
        { name: '数据分析' },
        { name: '预警管理' },
        { name: '智能标签' },
        { name: '设备智能化运维' },
        { name: '流程引擎' },
        { name: '可视化引擎' },
    ]
}

export const jiejueban = [
    {
        icon: require("@/assets/bg/jiejue3.png"),
        title: '智慧社区',
        text: '打造50+未来社区、老旧小区、新建社区的智能化提升建设'
    },
    {
        icon: require("@/assets/bg/jiejue4.png"),
        title: '数字孪生',
        text: '智慧楼宇、智慧园区、等建筑空间智能化、数字化联动'
    },
    {
        icon: require("@/assets/bg/jiejue5.png"),
        title: '基层治理',
        text: '助力基层工作减负'
    },
]

export const homesbanner = [
    {
        icon: require("@/assets/bg/banner1.png")
    },
    {
        icon: require("@/assets/bg/banner2.png")
    },
    {
        icon: require("@/assets/bg/banner3.png")
    },
    {
        icon: require("@/assets/bg/banner4.png")
    },
]

export const weYun = [
    {
        name: '公司简介',
    },
    {
        name: '新闻动态',
    },
    {
        name: '品牌荣誉',
    },
    {
        name: '权威资质',
    },
]

export const huoban = [
    {
        icon: require("@/assets/icon/huoban/img1/001.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img1/002.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img1/003.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img1/004.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img1/005.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img1/006.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img1/007.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img1/008.png"),
    },
]

export const huoban2 = [
    {
        icon: require("@/assets/icon/huoban/img2/001.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img2/002.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img2/003.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img2/004.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img2/005.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img2/006.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img2/007.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img2/008.png"),
    },
    // {
    //     icon: require("@/assets/icon/huoban/img2/009.png"),
    // },
]

export const huoban3 = [
    {
        icon: require("@/assets/icon/huoban/img3/001.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img3/002.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img3/003.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img3/004.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img3/005.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img3/006.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img3/007.png"),
    },
    {
        icon: require("@/assets/icon/huoban/img3/008.png"),
    },
]

export const zizhi = [
    {
        name: '安全生产许可证',
        icon: require("@/assets/bg/rongyu/001.jpg"),
        fang: true,
    },
    {
        name: '软件产品证书',
        icon: require("@/assets/bg/rongyu/002.jpg"),
        fang: true,
    },
    {
        name: '软件企业证书',
        icon: require("@/assets/bg/rongyu/003.jpg"),
        fang: true,
    },
    {
        name: '信用评价AAA级信用企业',
        icon: require("@/assets/bg/rongyu/013.jpg"),
        fang: true,
    },
    {
        name: '企业资信等级证书',
        icon: require("@/assets/bg/rongyu/014.jpg"),
        fang: true,
    },
    {
        name: '质量服务诚信单位',
        icon: require("@/assets/bg/rongyu/015.jpg"),
        fang: true,
    },
    {
        name: '重服务守信用企业',
        icon: require("@/assets/bg/rongyu/016.jpg"),
        fang: true,
    },
    {
        name: '重合同守信用企业',
        icon: require("@/assets/bg/rongyu/017.jpg"),
        fang: true,
    },
    {
        name: '重质量守信用企业',
        icon: require("@/assets/bg/rongyu/018.jpg"),
        fang: true,
    },
    {
        name: '建筑业劳务分包资质',
        icon: require("@/assets/bg/rongyu/004.jpg"),
        fang: false,
    },
    {
        name: '质量管理体系认证证书',
        icon: require("@/assets/bg/rongyu/005.jpg"),
        fang: false,
    },
    {
        name: '环境管理体系认证证书',
        icon: require("@/assets/bg/rongyu/006.jpg"),
        fang: false,
    },
    {
        name: '信息安全管理体系认证证书',
        icon: require("@/assets/bg/rongyu/007.jpg"),
        fang: false,
    },
    {
        name: '信息安全管理体系认证证书',
        icon: require("@/assets/bg/rongyu/008.jpg"),
        fang: false,
    },
    {
        name: '职业健康安全管理体系认证',
        icon: require("@/assets/bg/rongyu/009.jpg"),
        fang: false,
    },
    {
        name: '电信ICP+EDI证书',
        icon: require("@/assets/bg/rongyu/010.jpg"),
        fang: false,
    },
    {
        name: '电信ISP证书',
        icon: require("@/assets/bg/rongyu/011.jpg"),
        fang: false,
    },
    {
        name: '七星售后证书',
        icon: require("@/assets/bg/rongyu/012.jpg"),
        fang: false,
    },
    
]

export const ruanzhu = [
    {
        name: '街道巡查感知联动平台',
        icon: require("@/assets/bg/rongyu/201.jpg"),
        fang: false,
    },
    {
        name: '民政数智驾驶舱',
        icon: require("@/assets/bg/rongyu/202.jpg"),
        fang: false,
    },
    {
        name: '智慧旅游产品管理平台',
        icon: require("@/assets/bg/rongyu/203.jpg"),
        fang: false,
    },
    {
        name: '企业产值加速度平台',
        icon: require("@/assets/bg/rongyu/204.jpg"),
        fang: false,
    },
    {
        name: '数字乡村管理系统',
        icon: require("@/assets/bg/rongyu/205.jpg"),
        fang: false,
    },
    {
        name: '云茗之家APP软件',
        icon: require("@/assets/bg/rongyu/206.jpg"),
        fang: false,
    },
    {
        name: '云茗之家服务端APP软件',
        icon: require("@/assets/bg/rongyu/207.jpg"),
        fang: false,
    },
    {
        name: '云茗集团ERP管理系统',
        icon: require("@/assets/bg/rongyu/208.jpg"),
        fang: false,
    },
]


export const fazhanlist = [
    {
        name: '分销型合作伙伴',
        text: '具备城市与建筑空间ICT系统集成产品与服务的销售、咨询、和技术服务能力、和云茗科技进行资源整合、共拓市场、推动更多客户使用。',
        icon: require("@/assets/icon/fazhan1.png"),
    },
    {
        name: '解决方案合作伙伴',
        text: '具备智慧社区、智慧城市等自主软硬件产品能力，基于云茗科技的产品技术和服务，形成联合解决方案的合作伙伴。',
        icon: require("@/assets/icon/fazhan2.png"),
    },
    {
        name: '产品交付合作伙伴',
        text: '具备智慧物联感知平台产品的交付实施和本地化服务能力，向客户提供交付实施，架构设计和运维管理等服务提供商。',
        icon: require("@/assets/icon/fazhan3.png"),
    },
]


export const homefun = [
    {
        name: '智慧社区平台简介',
        text1: '平台简介',
        text2: '智慧社区平台是云茗科技潜心研发的社区综合智治与管理平台，专注于社区智能化建设提升、社区治理与服务一体化场景打造。综合为社区提供丰富多样的功能，满足社区各项工作需求。',
        icon: require("@/assets/bg/home2.png"),
    },
    {
        name: '智慧城市物联感知（AIOT）平台',
        text1: '平台简介',
        text2: '云茗科技智慧城市物联感知(AIOT)平台面向智能设备和空间智能化企业提供成熟、安全、高可用的物联网支撑平台；科缩短企业产品的研发周期，降低成本；满足企业的设备管理和服务运营需求，在物联网数据中提取价值',
        icon: require("@/assets/bg/home6.png"),
    },
    {
        name: '智慧园区网络建设',
        text1: '服务简介',
        text2: '云茗科技网络部致力于为各企业用户、家庭用户提供完善的网络解决方案，安全、稳定、高速的网络服务。向企业用户提供高效、简洁的数智化办公场景，安全稳定的人员管理系统。为家庭用户提供高速智能居家网络环境',
        icon: require("@/assets/bg/home7.png"),
    },
    {
        name: '个性化定制服务',
        text1: '服务简介',
        text2: '支持根据您的需求，快速定制开发智慧社区、智慧城市、智慧园区、智慧文旅等领域的项目，我们拥有丰富的实战经验，在多个领域有沉淀大量的解决方案、标准产品、技术框架等，形成专属的知识库。在相同领域可以快速，专业等构建出定制化系统',
        icon: require("@/assets/bg/home8.png"),
    },
]

export const homefun1 = [
    {
        name: '智慧社区重点功能',
        icon: require("@/assets/bg/home3.png"),
        child: [
            [
                {
                    name: '安防监控',
                    text: '对社区各个角落安装智能监控设备，后台实时可调阅查看社区实况，实现社区安防无死角',
                    icon: require("@/assets/icon/homefun/homei002.png"),
                },
                {
                    name: '电梯监控',
                    text: '紧急预警功能、AI识别电瓶车、AI识别检测遗留物品',
                    icon: require("@/assets/icon/homefun/homei003.png"),
                },
                {
                    name: '人员管理',
                    text: '人脸门禁+尾随分析、管理重点人员、还原人员追踪轨迹',
                    icon: require("@/assets/icon/homefun/homei004.png"),
                },
            ],
            [
                {
                    name: '智慧消防',
                    text: '以消防传感器为基础、还原消防设备具体点位、具备消防联动预警功能',
                    icon: require("@/assets/icon/homefun/homei005.png"),
                },
                {
                    name: '车辆管理',
                    text: '查询出入车辆具体信息、识别疫情区特殊车辆、AI识别车辆违停现象',
                    icon: require("@/assets/icon/homefun/homei006.png"),
                },
            ],
            [
                {
                    name: '物业管理',
                    text: '物业巡更与绩效、问题反馈与报修',
                    icon: require("@/assets/icon/homefun/homei007.png"),
                },
            ],
        ]
    },

    {
        name: 'AIOT平台重点功能',
        icon: require("@/assets/bg/home3.png"),
        child: [
            [
                {
                    name: '设备接入',
                    text: '物联云平台提供网关接入、设备直连和云云对接三种智能设备接入方案，同时支持对海康、大华等硬件厂商主流IoT设备生态的接入',
                    icon: require("@/assets/icon/homefun/homei102.png"),
                },
                {
                    name: '自动化运维',
                    text: '接入物联平台的所有IOT设备进行自动化运维配置和实时监测，当设备离线、或发生异常状态时自动报警产生预警信息',
                    icon: require("@/assets/icon/homefun/homei103.png"),
                },
                {
                    name: '智能边缘计算',
                    text: '通过AIoT技术实现本地设备的便捷接入，支持边缘网关的本地数据处理、缓存和计算，以及基于场景引擎的智能互联，实现云边协同，让连接更简单，边缘更智能+尾随分析、管理重点人员、还原人员追踪轨迹',
                    icon: require("@/assets/icon/homefun/homei104.png"),
                },
            ],
            [
                {
                    name: '低代码开发',
                    text: '开发者可通过集成SDK的方式，接入和输出物联网设备及相关业务数据。灵活的配置方式，允许客户端按需求管理设备、事件、场景、空间等不同维度的数据',
                    icon: require("@/assets/icon/homefun/homei105.png"),
                },
                {
                    name: '设备管理',
                    text: '提供可视化的IoT物联网设备接入和管理平台，实时查看设备运行状态，进行告警监测、远程控制、实时诊断等',
                    icon: require("@/assets/icon/homefun/homei106.png"),
                },
                {
                    name: '预警管理',
                    text: '支持对各类接入的IOT设备产生的预警信息进行智能化推送配置管理，确保预警及时处置闭环，避免事态扩大',
                    icon: require("@/assets/icon/homefun/homei107.png"),
                },
            ],
        ]
    },
    {
        name: '重点服务内容',
        icon: require("@/assets/bg/home3.png"),
        child: [
            [
                {
                    name: '宽带网络',
                    text: '业务范围覆盖移动、联通、电信等主流运营商宽带网络一站式接入，包含需求整体评估、详细方案设计、专业团队施工等服务，确保宽带入户，网络能用、好用',
                    icon: require("@/assets/icon/homefun/homei202.png"),
                },
                {
                    name: '视频会议',
                    text: '支持会议投屏、在线会议系统、视频直播自媒体、4K超高清、广角画面、无线/蓝牙麦克风、支持主流视频软件、多种播放方式(话筒、电视、电脑、手机、平板)',
                    icon: require("@/assets/icon/homefun/homei203.png"),
                },
                {
                    name: '门禁考勤',
                    text: '支持对企业门禁和考勤机的安装服务，实现刷脸开门、远程开门、密码开门、语音对讲、刷卡开门等功能+尾随分析、管理重点人员、还原人员追踪轨迹',
                    icon: require("@/assets/icon/homefun/homei204.png"),
                },
            ],
            [
                {
                    name: '视频监控',
                    text: '支持监控视频的安装安装服务，并实现监控视频的前端监控、手机软件管理，电脑端管理、数据传输交换、视频存储等功能',
                    icon: require("@/assets/icon/homefun/homei205.png"),
                },
                {
                    name: '综合布线',
                    text: '负责对网线、交换机、线槽、PVC管、网络模块、网络模块等组件的综合布线。合理规划设计，规范施工',
                    icon: require("@/assets/icon/homefun/homei206.png"),
                },
                {
                    name: '无线覆盖',
                    text: '支持无线网络的全域覆盖和信号增强。主要设备包括大型吸顶AP、家用的面板式AP、WIFI放大器、PoE.AC一体化千兆路由器、专业室外AP、无线桥接等',
                    icon: require("@/assets/icon/homefun/homei207.png"),
                },
            ],
        ]
    },
    {
        name: '重点服务内容',
        icon: require("@/assets/bg/home9.png"),
        child: [
            [
                {
                    name: '方案设计',
                    text: '根据您的需求，编写建设方案和设计方案，提供项目建设参考决策依据',
                    icon: require("@/assets/icon/homefun/homei302.png"),
                },
                {
                    name: '报价评估',
                    text: '根据项目需求情况和分析情况，快速进行项目报价评估方案',
                    icon: require("@/assets/icon/homefun/homei303.png"),
                },
                {
                    name: '专业产研团队',
                    text: '我们拥有各个领域专业的解决方案团队、产品专家、技术大咖等',
                    icon: require("@/assets/icon/homefun/homei304.png"),
                },
            ],
            [
                {
                    name: '快速实施部署',
                    text: '我们拥有多个场景下丰富的SDK组件库和灵活的API数据接口，可快速实现定制项目的开发和部署',
                    icon: require("@/assets/icon/homefun/homei305.png"),
                },
                {
                    name: '无差别交付',
                    text: '百分百按照您的需求进行开发实现，完成系统交付，并提供一对一专业培训',
                    icon: require("@/assets/icon/homefun/homei306.png"),
                },
            ],
            [
                {
                    name: '免费运维',
                    text: '在一定的周期内提供免费运维服务，售后使用问题即时响应，快速处理',
                    icon: require("@/assets/icon/homefun/homei307.png"),
                },
            ],
        ]
    },
]

export const jingdiananlilist = [
    {
        title: '智慧社区',
        id: 1,
        text: '未来社区、老旧小区改造、街道/片区智能化场景打造',
        child: [
            {
                title: '杭州市上城区杨柳郡未来社区',
                text: '杨柳郡社区通过“空间营造+数字化+长效运营”三位一体业务模式，打造社区操作系统，为政府、企业客户提供全流程服务，为居民创造美好生活。该社区围绕“青春杨柳、潮邻生活”的目标定位，以“一个邻里客厅、一套社区营造体系、一个用户服务平台、一套生活服务体系”为服务运营内容，串联政府端、居民端、商家端并为各方提供相应的服务和产品，通过资产管理、公共生活运营、平台运营等方式全面打造可复制、可生长、可持续的杨柳郡未来社区长效运营模式。',
                icon: require("@/assets/bg/anli/001.png"),
                tag: ['先进性社区'],
            },
            {
                title: '杭州市余杭区凤阳未来社区',
                text: '凤阳未来社区位于余杭街道，社区单元面积为32公顷，受益居民人口为8251人。社区立足“凤启同筑梦·阳帆展未来”发展定位，盘活15000平方米公共服务空间，重点打造一站式邻里中心“凤之邻”、凤邻市集等全龄友好空间。通过外部资源活用，联动教育机构承载社区老年大学等公益服务功能；通过积极发挥纽带作用，推动“社企融合”“新老余杭人融合”“景社融合”。',
                icon: require("@/assets/bg/anli/002.png"),
                tag: ['创新型社区'],
            },
            {
                title: '杭州市余杭区山西园未来社区',
                text: '山西园未来社区位于余杭街道，社区单元面积为60.4公顷，受益居民12580人。项目以“安居塔山下，乐融山西园”为主题，盘活公共服务空间3670平方米。通过集成街道全域物业综合服务等“系统化”工程，破解单个社区治理、运营难点，做好旧改“后半篇文章”；通过联动老旧小区改造提升改造宝塔公寓危旧楼房，升级13亩脏乱菜地成停车场等“具体化”项目；通过打造“一小区一品”小区品牌等“精准化”举措，激活居民全面参与自治。',
                icon: require("@/assets/bg/anli/003.png"),
                tag: ['引领型社区'],
            },
            {
                title: '杭州市上城区牛田智慧社区（老旧小区改造）',
                text: '牛田社区智慧指挥中心驾驶舱（小区三合一）提升改造项目，以创新和智能化为核心，实现了社区管理的全面升级。通过该项目，社区建立了社区综合指挥中心，全面提高整个社区的管理效率和响应速度，打造“微脑+手脚”的创新型社区管理新模式，确保社区管理服务的及时性和精准性。以物联感知设备的广泛对接应用和智能化改造，有效地节省了人力、物力和财力，提升了社区治理的经济效益，为社区治理提供了新的思路和模式。',
                icon: require("@/assets/bg/anli/004.png"),
                tag: ['智慧社区'],
            },
            {
                title: '萧山区北干街道广德、星畈里未来社区',
                text: '作为省级第七批创建的引领型未来社区，这是萧山区首个两社联创型未来社区，试图通过打破社区、社群、服务的有限界限，探索拆迁安置社区与城市社区融合发展的无限未来。这也是继社区合伙人、民生坊、强社公司、社创中心之后，北干街道为萧山社会治理现代化贡献的又一“首创经验”。',
                icon: require("@/assets/bg/anli/005.png"),
                tag: ['联创型社区'],
            },
            {
                title: '仁和街道云会村数字乡村样板村建设项目',
                text: '依托综合治理、便民服务、党群服务三大服务中心，云会村优化服务流程、健全工作机制、梳理制度规范。为让房东租得放心、房客住得安心，云会村针对流动人口管控难题，全面推进“舒心安居”系统，保障流动人口信息采集和居住处所安全管理机制长效运行。通过数智平台，为村居民提供多样化服务，及时解答群众疑问，提高办公效率，提升居民获得感幸福感。',
                icon: require("@/assets/bg/anli/006.png"),
                tag: ['数字乡村样板村' ],
            },
        ],
    },
    {
        title: '智慧城市',
        id: 2,
        text: '城市建筑空间信息化、智能化、一体化建设',
        child: [
            {
                title: 'xx区行政服务中心智慧楼宇建设',
                text: 'xx区审管办综合管理平台是一个为xx区行政服务中心提供智慧楼宇管理服务的物联网数字孪生平台，集成车辆、门禁、监控、监察、会议音频等物联数据，从楼内智慧大厅、智慧安防、公共服务、智慧办公等维度进行数据管理，通过物联设备感知楼内的实时状况及各类预警信息，并将预警信息下派到移动端进行处置，从而提高楼内的办公效率及安全性。',
                icon: require("@/assets/bg/anli/101.png"),
                tag: ['智慧楼宇', '行政服务中心'],
            },
            {
                title: '湖州南浔数字停车平台建设',
                text: '南浔镇中心城区数字停车一张图项目针对城区停车整合难、处置难、管理难、监控难等痛点问题，以数字化手段接入村社停车、停车场停车、路面停车等停车数据，实现从宏观到微观层面的精细化管理，在为居民提供便捷的停车服务的同时，针对违规停车、车位分配、收费问题和停车拥堵四类突出问题以数据可视化赋能解决，从而提升城区停车的数智化水平和能力',
                icon: require("@/assets/bg/anli/102.png"),
                tag: ['智慧停车' ],
            },
            {
                title: '萧山固废',
                text: '萧山区固废清洁直运大数据管理平台是综合应用计算机技术、无线网络技术、GIS地理信息技术、视频监控技术，通过建立统一管理系统，实现对清运作业效果、作业车辆、设备设施、作业人员、中转站、垃圾物终端处置的监管，以及对卫生环境的全程监控，使清运作业问题能够及早发现、快速解决。',
                icon: require("@/assets/bg/anli/103.png"),
                tag: ['智慧清运' ],
            },

        ],
    },
    {
        title: '智慧园区',
        id: 3,
        text: '产业园区智能化、数字化场景提升，以及园区网络全域建设等',
        child: [
            {
                title: '天时科创园',
                text: '杭州天时科创园是未来科技城早期工业厂房转型升级园区，主要承载海创园、人工智能等优质初创项目和企业。截至目前，已孵化服务150余家企业，其中规上企业7家，国家高新技术企业13家，培育海创园和人工智能项目20余项，园区先后获杭州市科技企业孵化器、浙江省小微企业园等荣誉。2024年5月，云茗科技与墨非天时达成战略合作，在园区网络运维与数字技术等方面，将迎来一个里程碑式的跨越，相信通过双方携手共同努力、精诚合作，必将开启高速发展的新篇章！ 分工不明确等问题，西溪湿地旅游服务由三部分组成：后台管理系统（管理人员使用）、西溪湿地旅游服务（用户端/教师端）；流程涉及研学产品前期创建及审批+用户报名+教师课程操作+课后反馈+营销数据集中展示。',
                icon: require("@/assets/bg/anli/201.png"),
                tag: ['科创园区'],
            },
            {
                title: '中豪桃源时代',
                text: '作为中豪前瞻性布局城北开发的首个作品，桃源时代包含大型购物中心、国际5A写字楼、SOHO办公、高星级酒店等多元业态。中豪桃源时代产业园地理位置优越，有智慧网谷、杭钢商圈、新天地商圈三大高能板块共同拱卫桃源板块。文化、产业、商业、生活多重交界，赋能桃源价值，谋定城市未来。2024年6月，云茗科技网络部与桃源时代产业园达成战略合作，长期为园区企业及商家的宽带网络建设服务，实现园区网络全域覆盖。',
                icon: require("@/assets/bg/anli/202.png"),
                tag: ['综合型园区' ],
            },

        ],
    },
    {
        title: '智慧文旅',
        id: 4,
        text: '景区空间智能化建设和数字化建设',
        child: [
            {
                title: '西溪湿地研学游',
                text: '浙江杭州西溪国家湿地公园，位于浙江省杭州市西湖区和余杭区交界处 ，距西湖不到5千米，规划总面积11.5平方千米，湿地内河流总长100多千米，约70%的面积为河港、池塘、湖漾、沼泽等水域。湿地公园内生态资源丰富、自然景观幽雅、文化积淀深厚，与西湖、西泠并称杭州“三西”。是中国第一个集城市湿地、农耕湿地、文化湿地于一体的国家级湿地公园。云茗科技为西溪湿地研学游场景打造数字化平台，实现景区研学游产品的管理、订单的管理、班级的管理、研学线路的管理、财务订单的管理等功能。助力西溪湿地研学游场景迈向信息化、数字化、便捷化。 分工不明确等问题，西溪湿地旅游服务由三部分组成：后台管理系统（管理人员使用）、西溪湿地旅游服务（用户端/教师端）；流程涉及研学产品前期创建及审批+用户报名+教师课程操作+课后反馈+营销数据集中展示。',
                icon: require("@/assets/bg/anli/301.png"),
                tag: ['标杆型景区' ],
            },
            {
                title: '余你相约',
                text: '余杭区“余你乡约”项目建设，通过为政府侧提供数据管理、统计、分析、预判等服务，群众侧服务端，提供吃、住、行、游、购、娱查询等服务，和为村庄提供农事活动、农家乐、农产品关联展示和推广服务，促进美丽乡村和美丽经济融合发展，创建乡村数字生活新服务典型应用，实现全区农村旅游资源互联互通、“跟着节气游乡村”活动数字化管理',
                icon: require("@/assets/bg/anli/302.png"),
                tag: ['数字乡村样板村' ],
            },

        ],
    },
    {
        title: '智慧校园',
        id: 5,
        text: '涵盖中学、小学、幼儿园等学校的智能化、数字化建设',
        child: [
            {
                title: '萧山开悦数智幼儿园',
                text: '在“数智化幼儿园” 的创建下，萧山将通过一套区域数智幼儿园系统，形成一个基于互联网的区域幼儿园互助共享共同体，进一步赋能孩子们的发展。萧山将进一步推进“111”数智幼儿园工程，即“1套系统+1个平台+1个样板”，构建“管理舱、成长舱、资源舱、互联舱”四舱一体化互联的数智幼儿园系统，通过区域内一个公共数智幼儿园平台，实现“数智”全方位赋能幼儿园保育、教育、教学质量提升。',
                icon: require("@/assets/bg/anli/401.png"),
                tag: ['智慧校园'],
            },
            {
                title: '萧山信息港初中',
                text: '萧山区信息港初中勤学通平台项目是专为中学师生出勤管理设计的可视化系统，依托物联感知设备、算法模型和多端联动，实时记录师生出勤数据，并通过驾驶舱进行可视化呈现，能够实现对迟到情况、缺勤情况、异常到校/离校等出勤异常情况，分角色、分权限即时推送，确保考勤管理的高效与透明，数据分析优化校园管理，提升教育质量。',
                icon: require("@/assets/bg/anli/402.png"),
                tag: ['智慧校园' ],
            },

        ],
    },
    {
        title: '其他',
        id: 6,
        text: '各个领域定制项目经典案例',
        child: [
            {
                title: '杭州市交投集团土地开发管理平台',
                text: '根据杭州交投集团对数字化、信息化的建设需求，采用新技术（大数据技术、物联网技术、移动互联技术、云计算技术）等技术，以及数据信息贯通的建设思路，为集团土地开发管理数字化转型提供综合性解决方案。系统平台通过积累数据资产，实现企业数 字化经营管理，满足企业对地块、项目的管控需求，规范地块经营标准化业务流程； 通过地块经营精细化管理，实现业务部门管理减负，从而通过数智化手段为配套建设项目生产赋能，实现企业降本增效。 字化经营管理，满足企业对地块、项目的管控需求，规范企业标准化业务流程； 通过地块经营精细化管理，实现业务部门管理减负，从而通过数智化手段为配套建设项目生产赋能，实现企业降本增效。',
                icon: require("@/assets/bg/anli/501.png"),
                tag: ['数智土地开发管理' ],
            },
            {
                title: 'xx区人武部数智征兵系统',
                text: 'xx区人武部数智征兵系统聚焦于征兵各环节数智赋能，通过搭建兵员数仓、构建智能模型、云端多跨协同等方式，构建潜力挖掘、资格预审、智能定兵、优待智配等模型算法，激发兵员数据价值，破解兵员底数不清、信息核验慢、量化考核难、优待知晓难等问题，提升xx区征兵数智化水平，实现公平、公正、廉洁、高效征兵。',
                icon: require("@/assets/bg/anli/502.png"),
                tag: ['数智征兵'],
            },
            {
                title: '彭埠街道酒店式公寓管理服务配套项目',
                text: '彭埠街道酒店式公寓管理服务配套项目以实名实人登记为核心，以人脸信息为抓手，配合人脸门禁道闸、人脸抓拍监控、人脸门锁等智能设备，实现从进入网约房所在商住楼区域开始，到在楼宇中的行动轨迹，到进入房间，全过程一脸通行，精准把握，可以进行有效溯源，实现登记即所住、来访数据明、预警可溯源、出警即精准的效果，实现从入门到入户的全过程把握，以实现摸清数据底数、实现以房管人和精准发现并打击违法犯罪行为，挤压违法犯罪行为产生和生存的空间',
                icon: require("@/assets/bg/anli/503.png"),
                tag: ['酒店式公寓管理'],
            },
            {
                title: 'xx公安生态执法',
                text: 'xx市公安局全域执法生态评估“芯链”平台以理念最新、功能最优，协同最强，管理最精,数智最佳的“芯链”平台为整体建设思路，开发了“执法生态链”、“执法服务链”、“执法数据链”等特色智慧场景，解决了法制支队的考核问题，使考核结果一目了然，是数字赋能城市治理的集中体现，进一步推动了xx市全域执法的治理体系与治理能力现代化',
                icon: require("@/assets/bg/anli/504.png"),
                tag: ['智慧执法管理'],
            },

        ],
    },
]


export const gongneng = [
    [
        {
            name: '人员管理',
            text: '社区人员居住管理、含常住和流动人口管理。人员出行管理，陌生人管理等，保障社区人员信息实时掌握。',
        },
        {
            name: '房屋管理',
            text: '对小区的各个楼幢、单元、楼层、户号等信息进行管理，并绑定每个房屋实时住户信息。实现房屋和人的精细化管理。',
        },
        {
            name: '车辆管理',
            text: '包括对小区各个出入口的道闸安装，车辆进出记录实时监控、停车费用缴费管理等。',
        },
        {
            name: '物业管理',
            text: '对社区的各个小区及对应的物业进行管理，如物业费用、水费、电费等费用进行缴费管理。以及居民反馈问题处理等功能。',
        },
    ],
    [
        {
            name: '邻里互助',
            text: '搭建邻里互助场景，居民可通过平台进行求助发布和认领求助等功能，促进邻里和谐发展。',
        },
        {
            name: '积分体系',
            text: '搭建社区积分体系，并通过参与活动、邻里互助、随手拍等场景进行积分获取，积分可兑换相关物品或服务。',
        },
        {
            name: '报事保修',
            text: '通过手机端小程序，可进行大小事件的上报，上报后物业管理人员会接收到上报信息并及时反馈或处理。',
        },
        {
            name: '在线缴费',
            text: '通过手机端小程序对居民的物业费用、水费、电费等费用进行实时在线缴费，高效便民。',
        },
    ],
    [
        {
            name: '智能门禁',
            text: '安装智能人脸门禁系统，对出入小区的所有人员人脸识别开门，并对出入记录信息进行管理，识别陌生人信息等，保障小区安全。',
        },
        {
            name: '安防监控',
            text: '对小区的各个角落和重要卡口位置安装智能监控设备，并支持通过后台实时调阅查看，实现社区安防无死角。',
        },
        {
            name: '消防安全',
            text: '在小区的各个楼层，车库等位置安装“液位/液压、烟感、充电桩监测仪”等消防设备，并接入到系统进行实时管理和查看。',
        },
        {
            name: '车辆道闸',
            text: '对小区大门及地下车库的各个出入口安装智能道闸系统，实现车辆的进出管理，缴费管理等。',
        },
    ],
]
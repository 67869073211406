// 设计稿以1920px为宽度
function setRem() {
  // 实际设备页面宽度和设计稿的比值
  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  // const htmlHeight = document.documentElement.clientHeight || document.body.clientHeight;
  // const designRatio = 1920 / 1080;
  // const realRatio = htmlWidth / htmlHeight;

  // let baseSize = 16;
  // let scale = htmlWidth / 1920;
  // document.documentElement.style.fontSize = ((baseSize * Math.min(scale, 2)) > 16 ? (baseSize * Math.min(scale, 2)) : 16) + 'px'
  // document.documentElement.style.fontSize = (baseSize * scale) + 'px';

  // 存在宽度够了，高度不够的情况
  // if (realRatio > designRatio) {
  //   document.documentElement.style.fontSize = (baseSize * scale) * (designRatio / realRatio) + 'px'
  // }


  // 测试
  let scale = htmlWidth / 10;
  document.documentElement.style.fontSize = scale + 'px';
}

setRem();

window.addEventListener('resize', () => {
  setRem();
});
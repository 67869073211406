// 移动端 数据
export const menu = [
    {
        name: '首页',
        child: [],
        show: false,
    },
    {
        name: '产品与服务',
        child: [
            { name: '智慧社区平台' },
            { name: '智慧物联网（AIOT平台）' },
            { name: '智慧园区网络建设' },
            { name: '专属定制服务' },
        ],
        show: false,
    },
    {
        name: '解决方案',
        child: [],
        show: false,
    },
    {
        name: '经典案例',
        child: [],
        show: false,
    },
    {
        name: '关于我们',
        child: [],
        show: false,
    },
]

export const images = [
    require('@/assets/m/banner1.png'),
    require('@/assets/m/banner2.png'),
    require('@/assets/m/banner3.png'),
    require('@/assets/m/banner4.png'),
]

export const hexin = [
    {
        name: '智慧社区平台',
        bg: require('@/assets/m/chanbg1.png'),
        icon: require('@/assets/m/chanicon1.png'),
    },
    {
        name: '智慧物联网平台',
        bg: require('@/assets/m/chanbg2.png'),
        icon: require('@/assets/m/chanicon2.png'),
    },
    {
        name: '智慧园区网络建设',
        bg: require('@/assets/m/chanbg3.png'),
        icon: require('@/assets/m/chanicon3.png'),
    },
    {
        name: '专属定制服务',
        bg: require('@/assets/m/chanbg4.png'),
        icon: require('@/assets/m/chanicon4.png'),
    },
]

export const fangan = [
    {
        name: '智慧社区解决方案',
        text: '助力新建住宅、存量社区、老旧街道等社区智慧化管理，构建智慧社区一体化管理平台',
        icon: require('@/assets/m/fanganicon1.png'),
    },
    {
        name: '智慧城市解决方案',
        text: '助力新建住宅、存量社区、老旧街道等社区智慧化管理，构建智慧社区一体化管理平台',
        icon: require('@/assets/m/fanganicon2.png'),
    },
]
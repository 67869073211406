<template>
  <div id="Mfangan">
    <mtabbarVue />
    <div class="banner flex ALcenter">
      <div class="banner-box">
        <div class="banner-box-tit">智慧社区解决方案</div>
        <div class="banner-box-text">
          助力新建住宅、存量社区、老旧街道等社区智慧化管理，构建智慧社区一体化管理平台
        </div>
        <div class="flex banner-box-box">
          <div class="banner-box-box-shiyong tac" @click="goTijiao">
            申请试用
          </div>
          <div class="banner-box-box-yanshi tac" @click="goTijiao">
            预约演示
          </div>
        </div>
      </div>
    </div>

    <div class="texing">
      <div class="texing-tit tac">方案特性</div>
      <div class="texing-box flex">
        <div class="texing-box-li" v-for="(item, index) in texing" :key="index">
          <div class="flex JCcenter">
            <img :src="item.icon" class="texing-box-li-icon" alt="" />
          </div>
          <div class="tac texing-box-li-name">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div class="fangan">
      <div class="fangan-tit tac">方案价值</div>
      <div class="fangan-box">
        <div
          class="fangan-box-li flex ALcenter"
          v-for="(item, index) in jiazhi"
          :key="index"
        >
          <img :src="item.icon" class="fangan-box-li-icon" alt="" />
          <div class="fangan-box-li-text">
            <div class="fangan-box-li-text-tit">{{ item.name }}</div>
            <div class="fangan-box-li-text-text textOne">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="fangan-tit tac">方案能力</div>
      <div class="fangan-img">
        <div class="fangan-img-icon flex ALcenter JCcenter">
          <img
            src="../assets/m/nengli1.png"
            class="fangan-img-icon-icon rotating-element"
            alt=""
          />
        </div>
        <!-- <img
          src="../assets/m/nengli1.png"
          class="fangan-img-icon rotating-element"
          alt=""
        /> -->
      </div>
      <div class="fangan-tiao"></div>
    </div>

    <MchangjingVue />

    <MkehuVue />

    <MbottomVue />
  </div>
</template>

<script>
import MchangjingVue from "../components/mcom/Mchangjing.vue";
import MkehuVue from "../components/mcom/Mkehu.vue";
import mtabbarVue from "@/components/Mtabbar.vue";
import MbottomVue from "@/components/mcom/Mbottom.vue";
export default {
  components: {
    MchangjingVue,
    MkehuVue,
    mtabbarVue,
    MbottomVue,
  },
  data() {
    return {
      texing: [
        {
          icon: require("@/assets/icon/Hfangan/tx1.png"),
          name: "适用性强",
          text: "可广泛适用于新建住宅、存量社区、老旧街道，通过捂脸科技提供场景赋能",
        },
        {
          icon: require("@/assets/icon/Hfangan/tx2.png"),
          name: "设备类型广泛",
          text: "跨设备类型、协议、厂商、地域实现统一连接，实现互联互通、数据和业务统一",
        },
        {
          icon: require("@/assets/icon/Hfangan/tx3.png"),
          name: "重塑业务",
          text: "不仅是监测，而是将设备与设备数据作为新的生产函数，融合ai助力物业构建智慧社区管理系统平台",
        },
        {
          icon: require("@/assets/icon/Hfangan/tx4.png"),
          name: "复制性强",
          text: "助力集团客户形成标准，快速批量落地，复制成本低、实现集团标准化和管控",
        },
      ],
      jiazhi: [
        {
          name: "提升社区治理和服务水平",
          text: "社区人员管理、房屋管理、物业管理、车辆管理等实现智能化，通过手机端小程序、管理端后台、可视化驾驶舱大屏多端智能化管理和分析。",
          icon: require("@/assets/icon/Hfangan/jz1.png"),
        },
        {
          name: "增强社区生活氛围和体验感",
          text: "打造社区交通、教育、健康、医疗等场景应用，搭建老年活动中心、公共邻里空间、健身中心、生活市集、社区图书馆等，增强社区生活氛围和体验感。",
          icon: require("@/assets/icon/Hfangan/jz2.png"),
        },
        {
          name: "降低社区安全和消防风险问题",
          text: "实时监控、自动化运维。实时检测监控视频、消防烟感等iot设备运行状态、如遇故障或报警信息自动化发生报警通知，相关人员收到信息可及时处理，保障社区安全。",
          icon: require("@/assets/icon/Hfangan/jz3.png"),
        },
        {
          name: "促进邻里互动，和谐共处",
          text: "社区和物业通过平台进行综合管理、活动发布、问题反馈处置等拉动邻里居民互动互助，促进邻里和谐发展。营造良好社区生活氛围。",
          icon: require("@/assets/icon/Hfangan/jz4.png"),
        },
      ],
    };
  },

  methods: {
    /**
     * 提交
     */
    goTijiao() {
      this.$router.push("/Mshenqing");
    },
  },
};
</script>

<style lang="less" scoped>
#Mfangan {
  width: 750px;
  .banner {
    width: 750px;
    height: 520px;
    background: url(~@/assets/m/fangan1.png) no-repeat;
    background-size: 100% 100%;
    .banner-box {
      margin-left: 44px;
      .banner-box-tit {
        width: 380px;
        font-weight: 600;
        font-size: 44px;
        color: #353c58;
        line-height: 52px;
        margin-bottom: 24px;
      }
      .banner-box-text {
        width: 380px;
        font-size: 24px;
        color: #64698b;
        line-height: 44px;
        margin-bottom: 24px;
      }

      .banner-box-box {
        .banner-box-box-shiyong {
          width: 192px;
          height: 60px;
          line-height: 60px;
          font-size: 28px;
          color: #ffffff;
          background: #038ef2;
          border-radius: 8px 8px 8px 8px;
          margin-right: 16px;
        }
        .banner-box-box-yanshi {
          width: 192px;
          height: 60px;
          line-height: 60px;
          font-size: 28px;
          color: #038ef2;
          border-radius: 8px 8px 8px 8px;
          border: 2px solid #038ef2;
        }
      }
    }
  }

  .texing {
    width: 750px;
    background: #fff;
    height: 300px;

    .texing-tit {
      width: 750px;
      height: 138px;
      line-height: 138px;
      font-weight: 600;
      font-size: 36px;
      color: #353c58;
    }

    .texing-box {
      height: 112px;
      .texing-box-li {
        flex: 1;
        .texing-box-li-icon {
          width: 48px;
          height: 48px;
          margin-bottom: 24px;
        }
        .texing-box-li-name {
          font-size: 28px;
          color: #353c58;
        }
      }
    }
  }

  .fangan {
    width: 750px;
    background: #f2f9ff;

    .fangan-tit {
      width: 750px;
      height: 126px;
      line-height: 126px;
      font-weight: 600;
      font-size: 36px;
      color: #353c58;
    }

    .fangan-box {
      width: 702px;
      margin: 0 24px;

      .fangan-box-li {
        width: 702px;
        height: 160px;
        background: url(~@/assets/m/fangan2.png) no-repeat;
        background-size: 100% 100%;
        margin-bottom: 24px;

        .fangan-box-li-icon {
          width: 88px;
          height: 88px;
          margin: 0 24px;
        }

        .fangan-box-li-text {
          width: 548px;
          .fangan-box-li-text-tit {
            width: 548px;
            height: 40px;
            line-height: 40px;
            font-weight: 500;
            font-size: 28px;
            color: #353c58;
            margin-bottom: 12px;
          }

          .fangan-box-li-text-text {
            width: 548px;
            height: 34px;
            line-height: 34px;
            font-size: 24px;
            color: #64698b;
          }
        }
      }

      .fangan-box-li:last-child {
        margin: 0;
      }
    }

    .fangan-img {
      width: 718px;
      height: 408px;
      margin: 0 16px;
      background: url(~@/assets/m/fangan3.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
      .fangan-img-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #038ef2;
        .fangan-img-icon-icon {
          width: 50.2px;
          height: 54px;
        }
      }

      .rotating-element {
        animation: rotate 2s linear infinite;
      }
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    .fangan-tiao {
      width: 750px;
      height: 78px;
    }
  }
}
</style>
<template>
  <div id="Mkehu">
    <div class="tit tac">客户案例</div>
    <div class="box">
      <div
        class="box"
        v-for="(item, index) in jingdiananlilist[0].child"
        :key="index"
        :class="index == 1 ? 'boxbot' : ''"
      >
        <img class="box-icon" :src="item.icon" alt="" />
        <div class="box-body">
          <div class="box-body-tit textOne">{{ item.title }}</div>
          <div class="box-li-text textOne">{{ item.text }}</div>
          <div class="box-li-tagbox flex">
            <div
              class="box-li-tagbox-tag"
              v-for="(item2, index2) in item.tag"
              :key="index2"
            >
              {{ item2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jingdiananlilist } from "@/store/datat.js";

export default {
  data() {
    return {
      jingdiananlilist,
    };
  },
};
</script>

<style lang="less" scoped>
#Mkehu {
  width: 750px;
  background: #f2f9ff;
  .tit {
    font-weight: 600;
    font-size: 36px;
    color: #353c58;
    width: 750px;
    height: 126px;
    line-height: 126px;
  }
  .box {
    width: 724px;
    margin-left: 24px;
    height: 574px;
    overflow-x: auto;
    white-space: nowrap;
    .box {
      width: 544px;
      height: 518px;
      margin: 0 24px 0 0;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      border: 2px solid #dfe8ee;
      display: inline-block;
      overflow: hidden;
      .box-icon {
        width: 544px;
        height: 304px;
        
      }

      .box-body {
        width: 488px;
        margin: 0 28px;
        .box-body-tit {
          width: 488px;
          height: 82px;
          line-height: 82px;
          font-weight: 500;
          font-size: 30px;
          color: #353c58;
        }

        .box-li-text {
          width: 488px;
          height: 42px;
          line-height: 42px;
          font-size: 26px;
          color: #64698b;
          margin-bottom: 12px;
        }

        .box-li-tagbox {
          width: 488px;
          height: 36px;
          .box-li-tagbox-tag {
            padding: 0 16px;
            height: 36px;
            line-height: 36px;
            background: rgba(3, 142, 242, 0.1);
            font-size: 20px;
            color: #038ef2;
            margin-right: 20px;
            border-radius: 4px;
          }

          .box-li-tagbox-tag:nth-child(2n) {
            padding: 0 16px;
            height: 36px;
            line-height: 36px;
            background: rgba(255, 114, 34, 0.1);
            font-size: 20px;
            color: #ff7222;
            margin-right: 20px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
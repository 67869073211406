<template>
  <div id="Mshenqing">
    <div class="imgbox flex ALcenter">
      <img src="../assets/m/logo.png" class="imgbox-img" alt="" />
    </div>
    <div class="tit">智慧物联连接世界 · 共创未来美好生活</div>
    <div class="text">提交信息，工作人员将在24小时内与您联系</div>
    <div class="right-forms">
      <div class="forms-bi flex ALcenter">
        <div class="forms-bi-left tac">*</div>
        <input
          type="text"
          :value="obj.gongsi"
          placeholder="请输入公司全称"
          class="forms-bi-right"
        />
      </div>
      <div class="forms-bi flex ALcenter">
        <div class="forms-bi-left tac">*</div>
        <input
          type="text"
          :value="obj.xingming"
          placeholder="请输入姓名"
          class="forms-bi-right"
        />
      </div>
      <div class="forms-bi flex ALcenter">
        <div class="forms-bi-left tac">*</div>
        <input
          type="text"
          :value="obj.zhiwei"
          placeholder="请输入职位"
          class="forms-bi-right"
        />
      </div>
      <div class="forms-bi flex ALcenter">
        <div class="forms-bi-left tac">*</div>
        <input
          type="text"
          maxlength="11"
          :value="obj.shoji"
          placeholder="请输入手机号"
          class="forms-bi-right"
        />
      </div>
      <div class="forms-bi flex ALcenter">
        <div class="forms-bi-left tac"></div>
        <input
          type="text"
          :value="obj.miaoshu"
          placeholder="请输入需求描述（选填）"
          class="forms-bi-right"
        />
      </div>

      <!-- 提交和切换 -->
      <div class="forms-submit tac hand-cursor" @click="tijiao">确定</div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "Mshenqing",
  data() {
    return {
      obj: {
        gongsi: null,
        xingming: null,
        zhiwei: null,
        shoji: null,
        miaoshu: null,
      },
    };
  },
  methods: {
    tijiao() {
      // console.log("关闭");
      Toast.success("提交成功");
      this.qingkong();
    },

    qingkong() {
      this.obj = {
        gongsi: null,
        xingming: null,
        zhiwei: null,
        shoji: null,
        miaoshu: null,
      };
      setTimeout(() => {
        // 返回上一级
        this.$router.go(-1);
      }, 2000);
    },
  },
};
</script>

<style lang="less" scoped>
#Mshenqing {
  width: 750px;
  .imgbox {
    width: 750px;
    height: 296px;
    .imgbox-img {
      width: 307px;
      height: 60px;
      margin-left: 40px;
    }
  }

  .tit {
    width: 670px;
    margin: 0 40px 16px;
    font-weight: 600;
    font-size: 44px;
    color: #353c58;
    line-height: 52px;
  }

  .text {
    width: 670px;
    margin: 0 40px 48px;
    font-weight: 400;
    font-size: 24px;
    color: #64698b;
    line-height: 28px;
  }

  .right-forms {
    width: 670px;
    margin: 0 40px;
    .forms-bi {
      width: 670px;
      height: 96px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 2px solid #e9e9e9;
      margin-bottom: 32px;
      .forms-bi-left {
        width: 58px;
        color: #ff3f3f;
        font-size: 28px;
      }

      .forms-bi-right {
        width: 612px;
        font-size: 28px;
        color: #353c58;
        border: 0;
        margin: 0;
        padding: 0;
      }
      .forms-bi-right:focus {
        outline: none;
      }
    }

    .forms-bi:last-child {
      margin-bottom: 40px;
    }

    .forms-submit {
      width: 670px;
      height: 96px;
      line-height: 96px;
      background: #038ef2;
      border-radius: 4px 4px 4px 4px;
      font-weight: 500;
      font-size: 30px;
      color: #ffffff;
      margin-top: 24px;
    }
  }
}
</style>
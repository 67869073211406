<template>
  <div class="top1" @click="scrollToTop"></div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top1 {
  width: 128px;
  height: 128px;
  position: fixed;
  top: 80vh;
  right: 24px;
  z-index: 999;
  background: url(~@/assets/m/top1.png) no-repeat;
  background-size: 100% 100%;
}
</style>
<template>
  <div id="Mabout">
    <div class="box">
      <div class="poabg"></div>
      <div class="poabg2"></div>
      <div class="poa2">
        <div class="tit tac"></div>
        <div class="text">
          浙江云茗科技股份有限公司成立于2023年，是一家领先的科技型集团化企业，是专业成熟的智慧城市承建方，自成立以来，公司专注于政企数字化改革领域，凭借着丰富的物联网场景平台开发经验和软硬件集成技术、智能化弱电施工及运维保障，建立了领先的解决方案规划、技术产品设计、项目工程建设等核心能力体系，致力于提供更加智能、高效的城市治理现代化数字解决方案。
        </div>
        <div class="text">
          公司始终以产品为核心、以技术为桥梁、以品质为保障，致力于数字赋能城市治理，推动城市治理能力现代化，打造数字化改革先进范本。致力于让城市管理更加高效、更加便捷、更加智慧！
        </div>
      </div>
    </div>
    <div class="title tac">发展历程</div>
    <div class="box2">
      <div class="box2-poa"></div>
      <div class="box2-box">
        <div class="bos flex JCspaceBetween ALcenter">
          <div class="bos-left"></div>
          <div class="bos-right">
            <div
              class="bos-right-li"
              v-for="(item, index) in licheng"
              :key="index"
            >
              <div
                :class="
                  index == 0 ? 'bos-right-li-time1' : 'bos-right-li-time2'
                "
              >
                {{ item.time }}
              </div>
              <div
                :class="
                  index == 0 ? 'bos-right-li-text1' : 'bos-right-li-text2'
                "
              >
                {{ item.text }}
              </div>
              <!-- 定位 -->
              <div
                :class="index == 0 ? 'bos-right-li-poa1' : 'bos-right-li-poa2'"
              ></div>
            </div>
          </div>
        </div>
        <div class="tiaos"></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      licheng: [
        {
          time: "2023年7月",
          text: "公司成立",
        },
        {
          time: "2024年4月",
          text: "网络部成立，业务拓展包括园区企业网络建设及家庭宽带安装办理。",
        },
        // {
        //   time: "2024年5月",
        //   text: "云茗科技与墨非天时签订战略合作协议",
        // },
        {
          time: "2024年7月",
          text: "公司成立一周年",
        },
      ],
    };
  },
};
</script>
  
  <style lang="less" scoped>
#Mabout {
  width: 750px;
  .box {
    width: 750px;
    height: 800px;
    //   background: url(~@/assets/m/about1.png) no-repeat;
    //   background-size: 100% 100%;
    position: relative;
    .poabg {
      position: absolute;
      left: 38px;
      top: 48px;
      width: 100px;
      height: 84px;
      background: url(~@/assets/m/about2.png) no-repeat;
      background-size: 100% 100%;
    }

    .poabg2 {
      position: absolute;
      left: 0;
      top: 0;
      width: 750px;
      height: 466px;
      background: url(~@/assets/m/about4.png) no-repeat;
      background-size: 100% 100%;
    }

    .poa2 {
      position: absolute;
      left: 0;
      top: 0;
      width: 750px;
      height: 800px;
      z-index: 2;
    }

    .tit {
      width: 750px;
      height: 94px;
    }

    .text {
      width: 690px;
      margin: 0 30px 40px;
      font-size: 28px;
      color: #353c58;
      line-height: 52px;
      text-indent: 4em;
    }
  }

  .title {
    width: 750px;
    height: 126px;
    line-height: 126px;
    font-weight: 600;
    font-size: 36px;
    color: #353c58;
  }

  .box2 {
    width: 750px;
    position: relative;
    height: 538px;

    .box2-poa {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 750px;
      height: 466px;
      background: url(~@/assets/m/about5.png) no-repeat;
      background-size: 100% 100%;
    }

    .box2-box {
      position: absolute;
      left: 0;
      top: 0;
      width: 750px;
      z-index: 2;

      .tiaos {
        width: 750px;
        height: 40px;
      }
      .bos {
        width: 668px;
        margin: 0 30px 0 52px;
        height: 498px;
        .bos-left {
          width: 2px;
          height: 100%;
          background: linear-gradient(
            120deg,
            rgba(3, 142, 242, 0.2) 0%,
            #038ef2 4%,
            #038ef2 89%,
            rgba(3, 142, 242, 0.2) 100%
          );
        }

        .bos-right {
          width: 622px;
          .bos-right-li {
            width: 622px;
            // height: 98px;
            margin-bottom: 60px;
            position: relative;
            .bos-right-li-time1 {
              font-weight: 600;
              font-size: 32px;
              color: #038ef2;
              line-height: 38px;
              margin-bottom: 12px;
            }
            .bos-right-li-time2 {
              font-weight: 500;
              font-size: 28px;
              color: #038ef2;
              line-height: 38px;
              margin-bottom: 12px;
            }

            .bos-right-li-text1 {
              font-size: 30px;
              color: #353c58;
              line-height: 35px;
            }
            .bos-right-li-text2 {
              font-size: 24px;
              color: #64698b;
              line-height: 28px;
            }

            .bos-right-li-poa1 {
              position: absolute;
              left: -59px;
              top: 8px;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background: #038ef2;
            }
            .bos-right-li-poa2 {
              position: absolute;
              left: -52px;
              top: 10px;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: #038ef2;
            }
          }

          .bos-right-li:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
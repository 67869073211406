<template>
  <div id="Homes">
    <div class="banner">
      <el-carousel indicator-position="none" height="32vw">
        <el-carousel-item v-for="(item, index) in homesbanner" :key="index">
          <img :src="item.icon" class="banner-banner" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <homefun />
    <jingdiananli />
    <fazhan />
    <huoban />
    <rongyu />
  </div>
</template>

<script>
import { homesbanner } from "@/store/datat.js";
import huoban from "../components/huoban.vue";
import rongyu from "../components/rongyu.vue";
import fazhan from "../components/fazhan.vue";
import homefun from "../components/homefun.vue";
import jingdiananli from "../components/jingdiananli.vue";
export default {
  name: "Homes",
  components: {
    huoban,
    rongyu,
    fazhan,
    homefun,
    jingdiananli,
  },
  data() {
    return {
      homesbanner: homesbanner,
    };
  },
};
</script>

<style lang="less" scoped>
#Homes {
  width: 1920px;
  overflow: hidden;
  .banner {
    width: 1920px;
    height: 600px;
    .banner-banner {
      width: 1920px;
      height: 600px;
    }
  }
}
</style>
<template>
  <div id="Tabbar">
    <div
      class="yizhounian flex ALcenter JCright"
      v-show="haobao && listId == 5"
    >
      <div class="yizhounian-chose hand-cursor" @click="guanbis"></div>
    </div>
    <div
      class="tabbarbox flex ALcenter JCspaceBetween"
      :class="[
        chanpinShow ? 'tabbarbg' : '',
        fangAnShow ? 'tabbarbg' : '',
        scrollTop != 0 ? 'tabbarbg' : '',
      ]"
    >
      <div class="tabbarbox-left flex ALcenter">
        <div class="tabbarbox-left-logo flex ALcenter JCcenter">
          <div class="logo"></div>
        </div>
        <div
          class="tabbarbox-left-item flex ALcenter JCcenter hand-cursor"
          @mouseenter="handleMouseEnter(item.id)"
          @mouseleave="handleMouseLeave(item.id)"
          v-for="(item, index) in list"
          :key="index"
          @click="clickIndex(item.id)"
        >
          <div class="fs8 fw5" :class="listId == item.id ? 'lan038' : 'hui353'">
            {{ item.name }}
          </div>
          <div
            :class="
              chanpinShow
                ? 'tabbarbox-left-item-arr2'
                : 'tabbarbox-left-item-arr1'
            "
            v-if="item.id == 2"
          ></div>
          <div
            :class="
              fangAnShow
                ? 'tabbarbox-left-item-arr2'
                : 'tabbarbox-left-item-arr1'
            "
            v-if="item.id == 3"
          ></div>
        </div>
      </div>
      <div
        class="tabbarbox-shenqing tac white fs7 hand-cursor"
        @click="opendialogshow"
      >
        申请试用
      </div>
      <div
        class="zheahaos"
        :class="haobao && listId == 5 ? 'zhuijiahei' : ''"
        v-show="chanpinShow || fangAnShow"
      ></div>
      <!-- 弹窗 产品与服务 -->
      <transition name="chanpint">
        <div
          class="chanpinbox"
          :class="haobao && listId == 5 ? 'zhuijiahei' : ''"
          v-show="chanpinShow"
          @mouseenter="handleMouseEnter(2)"
          @mouseleave="handleMouseLeave(2)"
        >
          <div class="chanpinbox-box flex">
            <div class="chanpinbox-box-left">
              <div class="chanpinbox-box-left-box">
                <div class="chanpinbox-box-left-box-titile">产品与服务中心</div>
                <div class="chanpinbox-box-left-box-text">
                  云茗科技产品与服务中心，核心产品主要包括“智慧社区平台、智慧物联网感知平台”，专注于社区智能化建设提升、社区治理与服务一体化场景打造。综合为社区提供丰富多样的功能平台，满足社区各项工作需求。
                </div>
                <div class="chanpinbox-box-left-box-img"></div>
              </div>
            </div>
            <div class="chanpinbox-box-right flex">
              <div
                class="chanpinbox-box-right-item"
                v-for="(item, index2) in chanpin"
                :key="index2"
              >
                <div class="chanpinbox-box-right-item-title hand-cursor" @click="goFangan(item)">
                  {{ item.name }}
                </div>
                <div
                  class="chanpinbox-box-right-item-li"
                  v-for="(chanzi, index3) in item.child"
                  :key="index3"
                >
                  <div class="chanpinbox-box-right-item-li-tit hand-cursor">
                    {{ chanzi.name }}
                  </div>
                  <div class="chanpinbox-box-right-item-li-box">
                    <div
                      class="chanpinbox-box-right-item-li-box-li hand-cursor"
                      v-for="(chansun, index4) in chanzi.child"
                      :key="index4"
                    >
                      {{ chansun.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- 弹窗 解决方案 -->
      <transition name="fangant">
        <div
          class="fanganbox"
          v-show="fangAnShow"
          :class="haobao && listId == 5 ? 'zhuijiahei' : ''"
          @mouseenter="handleMouseEnter(3)"
          @mouseleave="handleMouseLeave(3)"
        >
          <div class="fanganbox-box flex">
            <div class="fanganbox1">
              <div class="fanganbox1-box">
                <div class="fanganbox1-box-title">解决方案中心</div>
                <div class="fanganbox1-box-text">
                  云茗科技聚焦数字政务领域各应用场景，提供对应的专业解决方案，尤其在“智慧城市、智慧社区、智慧园区、智慧文旅”等领域，有诸多丰富、成熟的中大型项目落地经验。在行业解决方案上始终走在最前沿。
                </div>
                <div class="fanganbox1-box-img"></div>
              </div>
            </div>
            <div class="fanganbox2 flex">
              <div class="fanganbox2-left">
                <div class="fanganbox2-left-tit">{{ hangyej.name }}</div>
                <div class="fanganbox2-left-box flex fwW">
                  <div
                    class="fanganbox2-left-box-li hand-cursor"
                    v-for="(item, index5) in hangyej.child"
                    :key="index5"
                    @click="goFangan(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>

              <div class="fanganbox2-right">
                <div class="fanganbox2-right-tit">{{ tongyongj.name }}</div>
                <div class="fanganbox2-right-box flex fwW">
                  <div
                    class="fanganbox2-right-box-li hand-cursor"
                    v-for="(item, index5) in tongyongj.child"
                    :key="index5"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>

            <div class="fanganbox3">
              <el-carousel indicator-position="outside" height="325px">
                <el-carousel-item
                  v-for="(item, index6) in jiejueban"
                  :key="index6"
                >
                  <div class="fanganbox3-item">
                    <img :src="item.icon" class="fanganbox3-item-img" alt="" />
                    <div class="fanganbox3-item-title">{{ item.title }}</div>
                    <div class="fanganbox3-item-text">{{ item.text }}</div>
                    <div class="fanganbox3-item-btn flex hand-cursor">
                      <div class="lan038 fs7">查看详情</div>
                      <div class="fanganbox3-item-btn-icon"></div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <dialogbox></dialogbox>
  </div>
</template>

<script>
import dialogbox from "./dialogbox.vue";
import { chanpin, hangyej, tongyongj, jiejueban } from "@/store/datat.js";
export default {
  name: "Tabbar",
  components: {
    dialogbox,
  },
  data() {
    return {
      list: [
        {
          id: 1,
          name: "首页",
          icon: false,
        },
        {
          id: 2,
          name: "产品与服务",
          icon: true,
        },
        {
          id: 3,
          name: "解决方案",
          icon: true,
        },
        {
          id: 4,
          name: "经典案例",
          icon: false,
        },
        {
          id: 5,
          name: "关于我们",
          icon: false,
        },
      ],
      listId: 1,
      chanpinShow: false,
      fangAnShow: false,
      chanpin: chanpin,
      hangyej: hangyej,
      tongyongj: tongyongj,
      jiejueban: jiejueban,
      scrollTop: 0,
      path: null,
      haobao: true,
    };
  },

  watch: {
    $route(to, from) {
      console.log(to.path, from, "/tabbar-to.path");
      // console.log(this.$route.path, "/tabbarthis.$route.path");
      // this.path = this.$route.path;
      this.switchfun(to.path)
    },
  },
  methods: {
    handleScroll() {
      if (window.scrollY < 10 || document.documentElement.scrollTop < 10) {
        this.scrollTop = window.scrollY || document.documentElement.scrollTop;
      }
    },
    /**
     * 鼠标移入tabbar
     */
    handleMouseEnter(e) {
      // console.log("鼠标移入");
      if (e == 2) {
        this.chanpinShow = true;
        this.fangAnShow = false;
      } else if (e == 3) {
        this.fangAnShow = true;
        this.chanpinShow = false;
      } else {
        this.fangAnShow = false;
        this.chanpinShow = false;
      }
    },

    /**
     * 鼠标移除tabbar
     */
    handleMouseLeave(e) {
      // console.log("鼠标移出");
      if (e == 2) {
        this.chanpinShow = false;
      } else if (e == 3) {
        this.fangAnShow = false;
      } else {
        this.fangAnShow = false;
        this.chanpinShow = false;
      }
    },

    /**
     * 切换tabbar
     */
    clickIndex(e) {
      if (e == 2 || e == 3) {
        // 1
        this.listId = e;

        if (e == 2) {
          this.$router.push("/Hchanpin");
        } else if (e == 3) {
          this.$router.push("/Hfangan");
        }
      } else {
        this.listId = e;
        // 切换路由
        if (e == 1) {
          this.$router.push("/");
        } else if (e == 4) {
          this.$router.push("/Hanli");
        } else if (e == 5) {
          this.$router.push("/Habout");
        }
      }
      this.scrollToTop();
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },

    opendialogshow() {
      // console.log(this.$store.state.dialogshow, "this.$store.state.dialogshow");
      let ac = true;
      this.$store.commit("changedialogshow", ac);
    },
    switchfun(e) {
      if (e == "/") {
        this.listId = 1;
      } else if (e == "/Hchanpin") {
        this.listId = 2;
      } else if (e == "/Hfangan") {
        this.listId = 3;
      } else if (e == "/Hanli") {
        this.listId = 4;
      } else if (e == "/Habout") {
        this.listId = 5;
      }
    },

    guanbis() {
      this.haobao = false;
    },

    /**
     * 前往解决方案1
     */
    goFangan(e) {
      // console.log(e);
      if (e.name == "智慧社区") {
        this.$router.push("/Hfangan");
        this.listId = 3;
      } else if(e.name == "智慧社区平台") {
        this.$router.push("/Hchanpin");
        this.listId = 2;
      }
      this.handleMouseLeave(this.listId)
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.path = this.$route.path;
    this.switchfun(this.path);
    // console.log(this.path, "***");
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
#Tabbar {
  position: fixed;
  width: 1920px;
  // height: 64px;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 4px 4px 0px rgba(158, 158, 158, 0.04);
  z-index: 999999999;
  overflow: hidden;
  .yizhounian {
    width: 1920px;
    height: 60px;
    background: url(~@/assets/bg/yizhounian.png) no-repeat;
    background-size: 100% 100%;
    .yizhounian-chose {
      width: 24px;
      height: 24px;
      margin-right: 24px;
    }
  }
  .tabbarbg {
    background: #fff !important;
  }
  .tabbarbox {
    width: 1920px;
    height: 64px;
    .tabbarbox-left {
      .tabbarbox-left-logo {
        width: 208px;
        height: 64px;
        margin-right: 32px;
        .logo {
          width: 153px;
          height: 30px;
          margin: 0px 42px 0 30px;
          background: url(~@/assets/icon/logo.png) no-repeat;
          background-size: 100% 100%;
        }
      }

      .tabbarbox-left-item {
        height: 64px;
        padding-right: 30px;
        margin-right: 90px;
        .tabbarbox-left-item-arr1 {
          width: 10px;
          height: 10px;
          margin-left: 4px;
          background: url(~@/assets/icon/arr1.png) no-repeat;
          background-size: 100% 100%;
        }
        .tabbarbox-left-item-arr2 {
          width: 10px;
          height: 10px;
          margin-left: 4px;
          background: url(~@/assets/icon/arr2.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .tabbarbox-shenqing {
      width: 136px;
      height: 64px;
      line-height: 64px;
      background: #038ef2;
    }
    .zheahaos {
      position: fixed;
      left: 0;
      top: 64px;
      z-index: 99998;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.35);
    }
    /** 弹窗 产品与服务 */
    .chanpinbox {
      position: fixed;
      left: 0;
      top: 64px;
      z-index: 99998;
      width: 1920px;
      height: 654px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(158, 158, 158, 0.04);
      .chanpinbox-box {
        width: 1920px;
        height: 654px;
        border-top: 2px solid rgba(158, 158, 158, 0.04);
        .chanpinbox-box-left {
          width: 520px;
          height: 654px;
          background: url(~@/assets/bg/chanpin1.png) no-repeat;
          background-size: 100% 100%;
          .chanpinbox-box-left-box {
            width: 220px;
            height: 476px;
            margin: 36px 0 0 240px;
            .chanpinbox-box-left-box-titile {
              width: 220px;
              height: 52px;
              font-size: 20px;
              color: #038ef2;
            }

            .chanpinbox-box-left-box-text {
              width: 220px;
              height: 144px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #64698b;
              line-height: 24px;
            }

            .chanpinbox-box-left-box-img {
              width: 193px;
              height: 230px;
              margin-top: 50px;
              background: url(~@/assets/bg/chanpin2.png) no-repeat;
              background-size: 100% 100%;
            }
          }
        }

        .chanpinbox-box-right {
          margin-left: 68px;
          margin-top: 36px;
          .chanpinbox-box-right-item {
            margin-right: 100px;
            .chanpinbox-box-right-item-title {
              font-size: 20px;
              color: #038ef2;
              line-height: 23px;
              width: 192px;
            }
            .chanpinbox-box-right-item-li {
              width: 192px;
              margin-top: 24px;
              .chanpinbox-box-right-item-li-tit {
                width: 192px;
                font-size: 16px;
                font-weight: 600;
                color: #353c58;
                line-height: 19px;
              }

              .chanpinbox-box-right-item-li-box {
                width: 192px;
                .chanpinbox-box-right-item-li-box-li {
                  width: 192px;
                  font-size: 16px;
                  color: #64698b;
                  line-height: 19px;
                  margin-top: 10px;
                }
                .chanpinbox-box-right-item-li-box-li:first-child {
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }
    }

    .zhuijiahei {
      top: 124px !important;
    }
    /** 弹窗 解决方案 */
    .fanganbox {
      position: fixed;
      left: 0;
      top: 64px;
      z-index: 99998;
      width: 1920px;
      height: 480px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(158, 158, 158, 0.04);
      .fanganbox-box {
        width: 1920px;
        height: 480px;
        // border-top: 1px solid rgba(158, 158, 158, 0.04);
        .fanganbox1 {
          width: 520px;
          height: 480px;
          background: url(~@/assets/bg/jiejue1.png) no-repeat;
          background-size: 100% 100%;
          .fanganbox1-box {
            width: 220px;
            margin: 36px 0 0 240px;
            .fanganbox1-box-title {
              width: 220px;
              height: 52px;
              font-size: 20px;
              color: #038ef2;
              line-height: 23px;
            }
            .fanganbox1-box-text {
              width: 220px;
              font-size: 14px;
              color: #64698b;
              line-height: 24px;
            }

            .fanganbox1-box-img {
              width: 193px;
              height: 187px;
              margin-top: 50px;
              background: url(~@/assets/bg/jiejue2.png) no-repeat;
              background-size: 100% 100%;
            }
          }
        }

        .fanganbox2 {
          margin-left: 68px;
          height: 480px;
          .fanganbox2-left {
            width: 340px;
            margin-top: 36px;
            margin-right: 150px;
            .fanganbox2-left-tit {
              width: 340px;
              height: 52px;
              font-size: 20px;
              color: #038ef2;
              line-height: 23px;
            }

            .fanganbox2-left-box {
              width: 340px;
              .fanganbox2-left-box-li {
                width: 168px;
                margin-bottom: 24px;
                font-weight: 500;
                font-size: 16px;
                color: #353c58;
                line-height: 19px;
              }
            }
          }

          .fanganbox2-right {
            width: 192px;
            margin-top: 36px;
            margin-right: 141px;
            .fanganbox2-right-tit {
              width: 192px;
              height: 52px;
              font-size: 20px;
              color: #038ef2;
              line-height: 23px;
            }

            .fanganbox2-right-box {
              width: 192px;
              .fanganbox2-right-box-li {
                width: 192px;
                margin-bottom: 24px;
                font-weight: 500;
                font-size: 16px;
                color: #353c58;
                line-height: 19px;
              }
            }
          }
        }

        .fanganbox3 {
          width: 270px;
          margin-top: 36px;
          .fanganbox3-item {
            width: 270px;
            height: 325px;
            background: #ffffff;
            border: 1px solid #dfe8ee;
            margin: 0;
            .fanganbox3-item-img {
              width: 268px;
              height: 160px;
            }

            .fanganbox3-item-title {
              width: 230px;
              height: 54px;
              line-height: 54px;
              margin: 0 20px;
              font-weight: 500;
              font-size: 16px;
              color: #353c58;
            }

            .fanganbox3-item-text {
              width: 230px;
              margin: 0 20px;
              font-size: 14px;
              color: #64698b;
              line-height: 16px;
            }

            .fanganbox3-item-btn {
              width: 230px;
              margin: 12px 20px 0;
              .fanganbox3-item-btn-icon {
                width: 10px;
                height: 10px;
                margin-left: 2px;
                background: url(~@/assets/icon/arr3.png) no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }

    /** 遮罩层 */
    .zhezhao {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 99997;
      width: 1920px;
      height: 954px;
      background: #000;
    }

    /** 动画 */
    /* 定义过渡样式 产品与服务 */
    .chanpint-enter-active,
    .chanpint-leave-active {
      transition: opacity 0.5s;
    }
    .chanpint-enter, .chanpint-leave-to /* .chanpint-leave-active for <=2.1.8 */ {
      opacity: 0;
    }

    /* 定义过渡样式 解决方案 */
    .fangant-enter-active,
    .fangant-leave-active {
      transition: opacity 0.5s;
    }
    .fangant-enter, .fangant-leave-to /* .fangant-leave-active for <=2.1.8 */ {
      opacity: 0;
    }
    /** 这招 zhezhaot */

    .zhezhaot-enter-active,
    .zhezhaot-leave-active {
      transition: opacity 0.5s;
    }
    .zhezhaot-enter, .zhezhaot-leave-to /* .zhezhaot-leave-active for <=2.1.8 */ {
      opacity: 0;
    }
  }
}
</style>
<template>
  <div id="Mabout">
    <div class="box">
      <div class="poabg"></div>
      <div class="tit tac">关于我们</div>
      <div class="text">
        浙江云茗科技股份有限公司成立于2023年，是一家领先的科技型集团化企业，是专业成熟的智慧城市承建方，自成立以来，公司专注于政企数字化改革领域，凭借着丰富的物联网场景平台开发经验和软硬件集成技术、智能化弱电施工及运维保障，建立了领先的解决方案规划、技术产品设计、项目工程建设等核心能力体系，致力于提供更加智能、高效的城市治理现代化数字解决方案。
      </div>
      <div class="text">
        公司始终以产品为核心、以技术为桥梁、以品质为保障，致力于数字赋能城市治理，推动城市治理能力现代化，打造数字化改革先进范本。致力于让城市管理更加高效、更加便捷、更加智慧！
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#Mabout {
  width: 750px;
  .box {
    width: 750px;
    height: 1000px;
    background: url(~@/assets/m/about1.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    .poabg {
      position: absolute;
      left: 38px;
      top: 118px;
      width: 100px;
      height: 84px;
      background: url(~@/assets/m/about2.png) no-repeat;
      background-size: 100% 100%;
    }

    .tit {
      width: 750px;
      height: 118px;
      line-height: 118px;
      font-weight: 600;
      font-size: 36px;
      color: #353c58;
      margin-bottom: 46px;
    }

    .text {
      width: 690px;
      margin: 0 30px 40px;
      font-size: 28px;
      color: #353c58;
      line-height: 52px;
      text-indent: 4em;
    }
  }
}
</style>
<template>
  <div id="Fazhan">
    <div class="box">
      <div class="zhan1"></div>
      <div class="title tac">耦合发展・共同飞跃</div>
      <div class="text tac">
        诚邀有行业优势、区域优势及交付优势的合作伙伴，整合资源，实现产品耦合、商机互通、市场同力的生态发展模式
      </div>
      <div class="submitbox flex JCcenter">
        <div class="submit tac hand-cursor" @click="opendialogshow">
          立即成为合作伙伴
        </div>
      </div>
      <div class="itembox flex JCspaceBetween">
        <div
          class="itembox-box flex"
          v-for="(item, index) in fazhanlist"
          :key="index"
        >
          <img class="itembox-box-left" :src="item.icon" alt="" />
          <div class="itembox-box-right">
            <div class="itembox-box-right-titile">{{ item.name }}</div>
            <div class="itembox-box-right-text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <dialogbox></dialogbox>
  </div>
</template>

<script>
import dialogbox from "./dialogbox.vue";
import { fazhanlist } from "@/store/datat.js";
export default {
  name: "Fazhan",
  components: {
    dialogbox,
  },
  data() {
    return {
      fazhanlist,
    };
  },
  methods: {
    opendialogshow() {
      //   console.log(this.$store.state.dialogshow, "this.$store.state.dialogshow");
      let ac = true;
      this.$store.commit("changedialogshow", ac);
    },
  },
};
</script>

<style lang="less" scoped>
#Fazhan {
  width: 1920px;
  height: 464px;
  background: url(~@/assets/bg/fazhan1.png) no-repeat;
  background-size: 100% 100%;
  .box {
    width: 1440px;
    height: 464px;
    margin: 0 240px;
    .zhan1 {
      width: 1440px;
      height: 61px;
    }
    .title {
      width: 1440px;
      height: 39px;
      font-weight: 600;
      font-size: 28px;
      color: #353c58;
    }

    .text {
      width: 1440px;
      height: 20px;
      margin-top: 17px;
      font-size: 14px;
      color: #64698b;
    }

    .submitbox {
      height: 40px;
      width: 1440px;

      .submit {
        width: 176px;
        height: 40px;
        line-height: 40px;
        background: #038ef2;
        border-radius: 4px 4px 4px 4px;
        margin-top: 17px;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
      }
    }
    .itembox {
      width: 1440px;
      height: 190px;
      margin-top: 49px;

      .itembox-box {
        width: 464px;
        height: 190px;
        background: #ffffff;
        border-radius: 14px 14px 14px 14px;

        .itembox-box-left {
          width: 67px;
          height: 67px;
          margin: 46px 31px 0 24px;
        }

        .itembox-box-right {
          width: 312px;
          margin-top: 40px;
          .itembox-box-right-titile {
            width: 312px;
            height: 40px;
            font-weight: 500;
            font-size: 20px;
            color: #353c58;
          }

          .itembox-box-right-text {
            width: 312px;
            height: 66px;
            font-weight: 400;
            font-size: 14px;
            color: #64698b;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div id="Mchangjing">
    <div class="tit flex ALcenter JCcenter">
      <div>
        <div class="tit-tit tac">应用场景</div>
        <div class="tit-name tac">Application Scenarios</div>
      </div>
    </div>
    <div class="imgbox flex JCspaceBetween">
      <div class="imgbox-por" v-for="(item, index) in list" :key="index">
        <img
          :src="idx == item.id ? item.icon2 : item.icon1"
          :class="idx == item.id ? 'imgbox-da' : 'imgbox-xiao'"
          alt=""
        />
        <div v-if="idx == item.id" class="imgbox-por-poa">
          <div class="tit2">{{ list[idx - 1].name }}</div>
          <div class="text textTwo3">{{ list[idx - 1].text }}</div>
        </div>
        <div v-if="idx == item.id" class="imgbox-por-poa2"></div>
      </div>

      <!-- <div class="imgbox-xiao"></div>
        <div class="imgbox-da"></div>
        <div class="imgbox-xiao2"></div> -->
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      idx: 1,
      list: [
        {
          id: 1,
          name: "新建社区一体化智慧建设",
          text: "新建社区一体化智慧建设，围绕社区安防、消防、人员管理、房屋管理、事件管理、出入管理等场景进行建设，增强社区生活氛围和居住体验感，赋能社区、物业数字化管理，提升社区治理和服务水平。",
          icon1: require("@/assets/bg/Hchangjing1.png"),
          icon2: require("@/assets/bg/Hchangjing11.png"),
        },
        {
          id: 2,
          name: "老旧小区智能化改造",
          text: "老旧小区智能化场景提升，围绕社区安防、消防、人员管理、房屋管理、事件管理、出入管理等场景进行建设，增强社区生活氛围和居住体验感，赋能社区、物业数字化管理，提升社区治理和服务水平。",
          icon1: require("@/assets/bg/Hchangjing22.png"),
          icon2: require("@/assets/bg/Hchangjing2.png"),
        },
        {
          id: 3,
          name: "街道/片区智能化提升",
          text: "街道/片区智能化提升，围绕社区安防、消防、人员管理、房屋管理、事件管理、出入管理等场景进行建设，增强社区生活氛围和居住体验感，赋能社区、物业数字化管理，提升社区治理和服务水平。",
          icon1: require("@/assets/bg/Hchangjing3.png"),
          icon2: require("@/assets/bg/Hchangjing33.png"),
        },
      ],
      anyTime: null,
    };
  },
  mounted() {
    this.anyTime = setInterval(this.upAny, 3000);
  },
  beforeDestroy() {
    // 清除定时器
    if (this.anyTime) {
      clearInterval(this.anyTime);
    }
  },
  methods: {
    upAny() {
      if (this.idx == 1) {
        this.idx = 2;
      } else if (this.idx == 2) {
        this.idx = 3;
      } else if (this.idx == 3) {
        this.idx = 1;
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
#Mchangjing {
  width: 1920px;
  height: 696px;
  background: #f2f9ff;

  .tit {
    width: 1920px;
    height: 180px;
    .tit-tit {
      font-weight: 600;
      font-size: 28px;
      color: #353c58;
      line-height: 33px;
      margin-bottom: 10px;
    }

    .tit-name {
      font-size: 14px;
      color: #64698b;
      line-height: 16px;
      letter-spacing: 1px;
    }
  }

  .imgbox {
    width: 1440px;
    height: 516px;
    margin: 0 240px;
    .imgbox-por {
      position: relative;

      .imgbox-por-poa {
        position: absolute;
        width: 808px;
        left: 40px;
        bottom: 52px;
        z-index: 2;
        .tit2 {
          width: 808px;
          line-height: 34px;
          font-weight: 500;
          font-size: 24px;
          color: white;
          margin-bottom: 24px;
        }

        .text {
          width: 808px;
          font-size: 14px;
          color: white;
          line-height: 28px;
        }
      }

      .imgbox-por-poa2 {
        width: 888px;
        height: 516px;
        background: url(~@/assets/bg/Hchangjing4.png) no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }

      .imgbox-xiao {
        width: 256px;
        height: 516px;
      }

      .imgbox-da {
        width: 888px;
        height: 516px;
      }
    }
  }
}
</style>
<template>
  <div id="Jingdiananli">
    <div class="zhan1"></div>
    <div class="title tac">经典案例</div>
    <div class="text tac">打造100+场景案例</div>
    <div class="box flex fwW">
      <div
        class="box-li"
        v-for="(item, index) in jingdiananlilist[0].child"
        :key="index"
      >
        <img class="box-li-img" :src="item.icon" alt="" />
        <div class="box-li-title">{{ item.title }}</div>
        <div class="box-li-text textTwo3">{{ item.text }}</div>
        <div class="box-li-tagbox flex">
          <div class="box-li-tagbox-tag" v-for="(item2,index2) in item.tag" :key="index2">{{ item2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jingdiananlilist } from "@/store/datat.js";
export default {
  name: "Jingdiananli",
  data() {
    return {
      jingdiananlilist,
    };
  },
};
</script>

<style lang="less" scoped>
#Jingdiananli {
  width: 1920px;
  .zhan1 {
    width: 1920px;
    height: 60px;
  }

  .title {
    width: 1920px;
    height: 40px;
    font-weight: 600;
    font-size: 28px;
    color: #353c58;
  }

  .text {
    width: 1920px;
    height: 54px;
    line-height: 54px;
    font-size: 14px;
    color: #64698b;
  }

  .box {
    width: 1440px;
    margin: 0 240px;
    .box-li {
      width: 460px;
      height: 460px;
      background: #ffffff;
      box-shadow: 6px 6px 8px 0px rgba(223, 232, 238, 0.3);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #dfe8ee;
      margin-bottom: 30px;
      margin-right: 30px;
      font-size: 0;

      .box-li-img {
        width: 412px;
        height: 232px;
        margin: 24px 24px 0;
      }

      .box-li-title {
        width: 412px;
        margin: 0 24px 0;
        height: 58px;
        line-height: 58px;
        font-weight: 600;
        font-size: 16px;
        color: #353c58;
      }

      .box-li-text {
        width: 412px;
        margin: 0 24px 16px;
        height: 66px;
        line-height: 22px;
        font-size: 14px;
        color: #64698b;
      }

      .box-li-tagbox {
        margin: 0 24px;
        width: 412px;
        height: 24px;
        .box-li-tagbox-tag {
          padding: 0 16px;
          height: 24px;
          line-height: 24px;
          background: rgba(3, 142, 242, 0.1);
          font-size: 12px;
          color: #038ef2;
          margin-right: 10px;
        }

        .box-li-tagbox-tag:nth-child(2n) {
          padding: 0 16px;
          height: 24px;
          line-height: 24px;
          background: rgba(255, 114, 34, 0.1);
          font-size: 12px;
          color: #ff7222;
          margin-right: 10px;
        }
      }
    }

    .box-li:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
<template>
  <div id="Hchanpin">
    <div class="banner flex ALcenter JCcenter">
      <div class="banner-box">
        <div class="banner-box-title">智慧社区平台</div>
        <div class="banner-box-text">
          集成社区智能化建设和社区治理、社区服务场景的综合型社区一体化平台
        </div>
        <div class="banner-box-btnbox flex">
          <div class="banner-box-btnbox-submit tac" @click="opendialogshow">
            申请试用
          </div>
          <div class="banner-box-btnbox-submit2 tac" @click="opendialogshow">
            预约演示
          </div>
        </div>
      </div>
    </div>
    <div class="tabbar">
      <div class="tabbar-box flex JCcenter">
        <div
          class="tabbar-box-box fs9 hand-cursor"
          @click="switchIdx(item.id)"
          :class="item.id == idx ? 'lan038 fw6' : 'hui646'"
          v-for="(item, index) in tabbarlist"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="texing">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">方案特性</div>
        <div class="jieshao-box-text tac">Scheme Characteristics</div>
      </div>
      <div class="texing-box flex JCspaceBetween fwW">
        <div class="texing-box-li" v-for="(item, index) in texing" :key="index">
          <div class="texing-box-li-zhan1"></div>
          <div class="texing-box-li-item">
            <img :src="item.icon" class="texing-box-li-item-icon" alt="" />
            <div class="texing-box-li-item-zhan"></div>
            <div class="texing-box-li-item-name tac">{{ item.name }}</div>
            <div class="texing-box-li-item-text tac">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="gongneng flex ALcenter JCcenter">
      <div class="gongneng-box flex JCspaceBetween">
        <div class="gongneng-box-left">
          <div class="gongneng-box-left-tit">产品功能</div>
          <div class="gongneng-box-left-text">产品功能</div>
          <div class="gongneng-box-left-box">
            <div
              :class="
                anyIdx == item.id
                  ? 'gongneng-box-left-box-item'
                  : 'gongneng-box-left-box-itemno'
              "
              class="flex hand-cursor"
              v-for="(item, index) in anyList"
              :key="index"
              @click="switchany(item.id)"
            >
              <div class="gongneng-box-left-box-itembox-li">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="gongneng-box-right">
          <div class="gongneng-box-right-box flex fwW">
            <div class="gongneng-box-right-poa"></div>
            <div
              class="gongneng-box-right-box-li"
              v-for="(item, index) in gongneng[anyIdx - 1]"
              :key="index"
            >
              <div class="gongneng-box-right-box-li-tit">{{ item.name }}</div>
              <div class="gongneng-box-right-box-li-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <HchangjingVue />

    <!-- <div class="changjing">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">应用场景</div>
        <div class="jieshao-box-text tac">Application Scenarios</div>
      </div>
      <div class="changjing-box flex JCspaceBetween">
        <div class="changjing-box-bg1">
          <div class="changjing-box-bg1-poa1"></div>
          <div class="changjing-box-bg1-poa2 tac white fs8 fw6">
            新建社区一体化智慧建设
          </div>
        </div>
        <div class="changjing-box-bg2">
          <div class="changjing-box-bg2-poa1"></div>
          <div class="changjing-box-bg2-poa2 white fs8 fw6">
            <div class="changjing-box-bg2-poa2-tit">老旧小区智能化建设</div>
            <div class="changjing-box-bg2-poa2-text">
              老旧小区智能化场景提升，围绕社区安防、消防、人员管理、房屋管理、事件管理、出入管理等场景进行建设，增强社区生活氛围和居住体验感，赋能社区、物业数字化管理，提升社区治理和服务水平。
            </div>
          </div>
        </div>
        <div class="changjing-box-bg3">
          <div class="changjing-box-bg3-poa1"></div>
          <div class="changjing-box-bg3-poa2 tac white fs8 fw6">
            街道/片区智能化提升
          </div>
        </div>
      </div>
    </div> -->

    <div class="anli">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">客户案例</div>
        <div class="jieshao-box-text tac">Customer Cases</div>
      </div>
      <div class="neirongbox flex JCspaceBetween fwW">
        <div
          class="neirongbox-li"
          v-for="(item, index) in jingdiananlilist[0].child"
          :key="index"
          v-show="index < 2"
        >
          <img class="neirongbox-li-img" :src="item.icon" alt="" />
          <div class="neirongbox-li-title fs10">{{ item.title }}</div>
          <div class="neirongbox-li-text textTwo3">{{ item.text }}</div>
          <div class="neirongbox-li-tagbox flex">
            <div
              class="neirongbox-li-tagbox-tag"
              v-for="(item2, index2) in item.tag"
              :key="index2"
            >
              {{ item2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jingdiananlilist, gongneng } from "@/store/datat.js";
import HchangjingVue from '../components/Hchangjing.vue';
// import { chanpin } from "../store/datat";
export default {
  name: "Hchanpin",
  components: {
    HchangjingVue
  },
  data() {
    return {
      idx: 1,
      jingdiananlilist,
      gongneng,
      tabbarlist: [
        {
          id: 1,
          name: "产品特性",
        },
        {
          id: 2,
          name: "产品架构",
        },
        {
          id: 3,
          name: "产品功能",
        },
        {
          id: 4,
          name: "应用场景",
        },
        {
          id: 5,
          name: "客户案例",
        },
      ],
      texing: [
        {
          name: "全面覆盖",
          text: "云茗科技智慧社区平台功能丰富，覆盖社区治理、服务、安防等全域场景",
          icon: require("@/assets/icon/Hchanpin/cp1.png"),
        },
        {
          name: "产品成熟",
          text: "已成功部署100+个社区稳健运行，服务社区居民，提升社区治理和服务水平",
          icon: require("@/assets/icon/Hchanpin/cp2.png"),
        },
        {
          name: "灵活定制",
          text: "在标准化软件产品和服务的基础上，在PC端、小程序端、大屏端等提供灵活的定制化开发服务",
          icon: require("@/assets/icon/Hchanpin/cp3.png"),
        },
        {
          name: "多端支持",
          text: "支持手机端小程序、电脑端管理后台、和可视化数据大屏。满足各种场景下的系统使用需求",
          icon: require("@/assets/icon/Hchanpin/cp4.png"),
        },
        {
          name: "技术先进",
          text: "采用先进的物联网技术、云计算技术、3D建模等前沿技术，保障平台的高效运转",
          icon: require("@/assets/icon/Hchanpin/cp5.png"),
        },
        {
          name: "持续升级",
          text: "持续不断提供软件产品的升级服务，为用户长期创造价值",
          icon: require("@/assets/icon/Hchanpin/cp6.png"),
        },
      ],
      anyIdx: 1,
      anyList: [
        {
          id: 1,
          name: "社区治理场景",
        },
        {
          id: 2,
          name: "社区服务场景",
        },
        {
          id: 3,
          name: "社区安防建设",
        },
      ],
      anyTime: null,
    };
  },
  methods: {
    switchIdx(e) {
      this.idx = e;
    },

    opendialogshow() {
      let ac = true;
      this.$store.commit("changedialogshow", ac);
    },

    switchany(e) {
      this.anyIdx = e;
      //   clearInterval(this.anyTime);
      //   setTimeout(() => {
      //     this.upAny();
      //   }, 2000);
    },
    upAny() {
      if (this.anyIdx == 1) {
        this.anyIdx = 2;
      } else if (this.anyIdx == 2) {
        this.anyIdx = 3;
      } else if (this.anyIdx == 3) {
        this.anyIdx = 1;
      }
    },
  },
  mounted() {
    this.anyTime = setInterval(this.upAny, 2000);
  },
  beforeDestroy() {
    // 清除定时器
    if (this.anyTime) {
      clearInterval(this.anyTime);
    }
  },
};
</script>

<style lang="less" scoped>
#Hchanpin {
  width: 1920px;
  .banner {
    width: 1920px;
    height: 500px;
    background: url(~@/assets/bg/Hchanpin1.png) no-repeat;
    background-size: 100% 100%;
    .banner-box {
      width: 1440px;
      margin: 0 240px;
      .banner-box-title {
        width: 1440px;
        height: 59px;
        font-weight: 600;
        font-size: 36px;
        color: #353c58;
        line-height: 59px;
        margin-bottom: 12px;
      }

      .banner-box-text {
        width: 580px;
        font-size: 16px;
        color: #64698b;
        line-height: 26px;
        margin-bottom: 36px;
      }

      .banner-box-btnbox {
        width: 580px;
        .banner-box-btnbox-submit {
          width: 160px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #ffffff;
          background: #038ef2;
          border-radius: 4px 4px 4px 4px;
          margin-right: 16px;
        }
        .banner-box-btnbox-submit2 {
          width: 160px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #038ef2;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #038ef2;
        }
      }
    }
  }

  .tabbar {
    width: 1920px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.25);
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 4px;

    .tabbar-box {
      width: 1440px;
      height: 80px;
      margin: 0 240px;

      .tabbar-box-box {
        height: 80px;
        line-height: 80px;
        margin-right: 230px;
      }
      .tabbar-box-box:last-child {
        margin-right: 0;
      }
    }
  }

  .texing {
    width: 1920px;
    height: 754px;
    background: #ffffff;
    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 19px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 47px;
        font-size: 14px;
        color: #64698b;
      }
    }

    .texing-box {
      width: 1440px;
      margin: 0 240px;
      .texing-box-li {
        width: 410px;
        height: 228px;
        margin-bottom: 44px;
        .texing-box-li-zhan1 {
          width: 410px;
          height: 40px;
        }

        .texing-box-li-item {
          width: 410px;
          height: 188px;
          width: 410px;
          height: 188px;
          background: #f2f9ff;
          border-radius: 14px 14px 14px 14px;
          position: relative;
          .texing-box-li-item-icon {
            position: absolute;
            width: 76px;
            height: 76px;
            left: 50%;
            top: -40px;
            transform: translateX(-50%);
          }

          .texing-box-li-item-zhan {
            height: 60px;
          }

          .texing-box-li-item-name {
            width: 294px;
            height: 28px;
            line-height: 28px;
            font-weight: 600;
            font-size: 20px;
            color: #353c58;

            margin: 0 58px 0;
          }
          .texing-box-li-item-text {
            width: 294px;
            font-size: 14px;
            color: #64698b;
            line-height: 24px;
            margin: 16px 58px 0;
          }
        }
      }
    }
  }

  .gongneng {
    width: 1920px;
    height: 531px;
    background: url(~@/assets/bg/Hchanpin2.png) no-repeat;
    background-size: 100% 100%;

    .gongneng-box {
      width: 1440px;
      margin: 0 240px;
      .gongneng-box-left {
        width: 296px;
        .gongneng-box-left-tit {
          width: 296px;
          height: 39px;
          margin-bottom: 17px;
          font-weight: 600;
          font-size: 28px;
          color: #ffffff;
        }

        .gongneng-box-left-text {
          width: 296px;
          height: 17px;
          margin-bottom: 54px;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 1px;
        }

        .gongneng-box-left-box {
          width: 296px;
          border-left: 1px solid #fff;

          .gongneng-box-left-box-item {
            width: 296px;
            height: 64px;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(255, 255, 255, 0.05) 100%
            );
            border-left: 4px solid #fff;
            .gongneng-box-left-box-itembox-li {
              margin-left: 26px;
              height: 64px;
              line-height: 64px;
              font-weight: 600;
              font-size: 18px;
              color: #ffffff;
            }
          }

          .gongneng-box-left-box-itemno {
            width: 296px;
            height: 64px;
            .gongneng-box-left-box-itembox-li {
              margin-left: 30px;
              height: 64px;
              line-height: 64px;
              font-weight: 400;
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
      }

      .gongneng-box-right {
        width: 1099px;
        height: 331px;
        background: rgba(255, 255, 255, 0.85);
        box-shadow: 0px 4px 8px 0px rgba(0, 115, 198, 0.1);
        border-radius: 8px 8px 8px 8px;
        position: relative;

        .gongneng-box-right-box {
          width: 991px;
          margin: 0 54px;
          position: relative;
          .gongneng-box-right-poa {
            width: 991px;
            height: 1px;
            background: #dfe8ee;
            position: absolute;
            left: 0;
            top: 165px;
          }
          .gongneng-box-right-box-li {
            width: 400px;
            height: 160px;
            margin-right: 136px;

            .gongneng-box-right-box-li-tit {
              width: 400px;
              height: 25px;
              line-height: 25px;
              font-weight: 600;
              font-size: 18px;
              color: #353c58;
              margin: 44px 0 16px 0;
            }

            .gongneng-box-right-box-li-text {
              width: 400px;
              font-size: 14px;
              color: #64698b;
              line-height: 24px;
            }
          }
          .gongneng-box-right-box-li:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .changjing {
    width: 1920px;
    background: #f2f9ff;
    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 17px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 50px;
        font-size: 14px;
        color: #64698b;
      }
    }

    .changjing-box {
      width: 1440px;
      height: 516px;
      margin: 0 240px;
      .changjing-box-bg1 {
        width: 256px;
        height: 516px;
        background: rgba(0, 0, 0, 0.5);
        background: url(~@/assets/bg/Hchangjing1.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        .changjing-box-bg1-poa1 {
          width: 256px;
          height: 516px;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        .changjing-box-bg1-poa2 {
          width: 256px;
          height: 22px;
          position: absolute;
          left: 0;
          bottom: 52px;
          z-index: 2;
        }
      }
      .changjing-box-bg2 {
        width: 888px;
        height: 516px;
        background: rgba(0, 0, 0, 0.5);
        background: url(~@/assets/bg/Hchangjing2.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        .changjing-box-bg2-poa1 {
          width: 888px;
          height: 516px;
          background: url(~@/assets/bg/Hchangjing4.png) no-repeat;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        .changjing-box-bg2-poa2 {
          width: 808px;
          position: absolute;
          left: 40px;
          bottom: 52px;
          z-index: 2;
          .changjing-box-bg2-poa2-tit {
            font-weight: 600;
            font-size: 24px;
            color: #ffffff;
            margin-bottom: 24px;
          }

          .changjing-box-bg2-poa2-text {
            font-size: 14px;
            color: #ffffff;
            line-height: 28px;
          }
        }
      }
      .changjing-box-bg3 {
        width: 256px;
        height: 516px;
        background: rgba(0, 0, 0, 0.5);
        background: url(~@/assets/bg/Hchangjing3.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        .changjing-box-bg3-poa1 {
          width: 256px;
          height: 516px;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        .changjing-box-bg3-poa2 {
          width: 256px;
          height: 22px;
          position: absolute;
          left: 0;
          bottom: 52px;
          z-index: 2;
        }
      }
    }
  }

  .anli {
    width: 1920px;
    background: #f2f9ff;
    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 17px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 50px;
        font-size: 14px;
        color: #64698b;
      }
    }

    .neirongbox {
      width: 1440px;
      margin: 0 240px;
      .neirongbox-li {
        width: 688px;
        height: 631px;
        background: #ffffff;
        border: 1px solid #dfe8ee;
        margin-bottom: 41px;
        font-size: 0;

        .neirongbox-li-img {
          width: 688px;
          height: 386px;
        }

        .neirongbox-li-title {
          width: 636px;
          margin: 0 25px;
          height: 68px;
          line-height: 68px;
        }

        .neirongbox-li-text {
          width: 636px;
          margin: 0 25px;
          font-size: 14px;
          color: #64698b;
          line-height: 24px;
          height: 96px;
          margin-bottom: 16px;
        }

        .neirongbox-li-tagbox {
          margin: 0 24px;
          width: 412px;
          height: 24px;
          .neirongbox-li-tagbox-tag {
            padding: 0 16px;
            height: 24px;
            line-height: 24px;
            background: rgba(3, 142, 242, 0.1);
            font-size: 12px;
            color: #038ef2;
            margin-right: 10px;
          }

          .neirongbox-li-tagbox-tag:nth-child(2n) {
            padding: 0 16px;
            height: 24px;
            line-height: 24px;
            background: rgba(255, 114, 34, 0.1);
            font-size: 12px;
            color: #ff7222;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div id="Hanli">
    <div class="banner flex ALcenter JCcenter">
      <div class="banner-box">
        <div class="banner-box-title tac">累计打造100+经典案例</div>
        <div class="banner-box-text tac">
          Accumulated Over 100 Classic Cases Created
        </div>
      </div>
    </div>
    <div class="tabbar">
      <div class="tabbar-box flex JCcenter">
        <div
          class="tabbar-box-li hand-cursor fs9"
          @click="switchIdx(item.id)"
          :class="item.id == idx ? 'lan308 fw6' : 'hui646'"
          v-for="(item, index) in jingdiananlilist"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="neirong">
      <div class="zhan1"></div>
      <div class="tit tac">{{ jingdiananlilist[idx - 1].title }}</div>
      <div class="text tac">{{ jingdiananlilist[idx - 1].text }}</div>
      <div class="neirongbox flex JCspaceBetween fwW">
        <div
          class="neirongbox-li"
          v-for="(item, index) in jingdiananlilist[idx - 1].child"
          :key="index"
        >
          <img :src="item.icon" class="neirongbox-li-img" alt="" />
          <div class="neirongbox-li-title fs10 textOne">{{ item.title }}</div>
          <div class="neirongbox-li-text textTwo4">{{ item.text }}</div>
          <div class="neirongbox-li-tagbox flex">
            <div
              class="neirongbox-li-tagbox-tag"
              v-for="(item2, index2) in item.tag"
              :key="index2"
            >
              {{ item2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhan2"></div>
  </div>
</template>

<script>
import { jingdiananlilist } from "@/store/datat.js";
export default {
  name: "Hanli",
  data() {
    return {
      idx: 1,
      jingdiananlilist,
    };
  },
  methods: {
    switchIdx(e) {
      this.idx = e;
    },
  },
};
</script>

<style lang="less" scoped>
#Hanli {
  width: 1920px;
  .banner {
    width: 1920px;
    height: 420px;
    background: url(~@/assets/bg/Hanli.png) no-repeat;
    background-size: 100% 100%;
    .banner-box {
      width: 1920px;
      .banner-box-title {
        width: 1920px;
        height: 59px;
        font-size: 42px;
        color: #353C58;
        line-height: 59px;
        margin-bottom: 12px;
      }

      .banner-box-text {
        width: 1920px;
        height: 22px;
        font-size: 18px;
        color: #64698b;
        line-height: 22px;
        letter-spacing: 2px;
      }
    }
  }

  .tabbar {
    width: 1920px;
    height: 80px;
    background: #FFFFFF;
box-shadow: 0px 4px 4px 0px rgba(219,219,219,0.25);
border-radius: 0px 0px 0px 0px;

    .tabbar-box {
      width: 1440px;
      height: 80px;
      margin: 0 240px;
      .tabbar-box-li {
        height: 80px;
        line-height: 80px;
        margin-right: 180px;
      }

      .tabbar-box-li:last-child {
        margin-right: 0;
      }
    }
  }

  .neirong {
    width: 1440px;
    margin: 0 240px;
    .zhan1 {
      width: 1440px;
      height: 64px;
    }

    .tit {
      width: 1440px;
      height: 40px;
      line-height: 40px;
      font-weight: 700;
      font-size: 28px;
      color: #353c58;
      margin-bottom: 17px;
    }

    .text {
      width: 1440px;
      height: 20px;
      margin-bottom: 40px;
      font-size: 14px;
      color: #64698b;
    }

    .neirongbox {
      width: 1440px;
      .neirongbox-li {
        width: 688px;
        height: 631px;
        background: #ffffff;
        border: 1px solid #dfe8ee;
        margin-bottom: 41px;
        font-size: 0;
        .neirongbox-li-img {
          width: 688px;
          height: 386px;
        }

        .neirongbox-li-title {
          width: 636px;
          margin: 0 25px;
          height: 68px;
          line-height: 68px;
        }

        .neirongbox-li-text {
          width: 636px;
          margin: 0 25px;
          font-size: 14px;
          color: #64698b;
          line-height: 24px;
          height: 96px;
          margin-bottom: 16px;
        }

        .neirongbox-li-tagbox {
          margin: 0 24px;
          width: 412px;
          height: 24px;
          .neirongbox-li-tagbox-tag {
            padding: 0 16px;
            height: 24px;
            line-height: 24px;
            background: rgba(3, 142, 242, 0.1);
            font-size: 12px;
            color: #038ef2;
            margin-right: 10px;
          }

          .neirongbox-li-tagbox-tag:nth-child(2n) {
            padding: 0 16px;
            height: 24px;
            line-height: 24px;
            background: rgba(255, 114, 34, 0.1);
            font-size: 12px;
            color: #ff7222;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .zhan2 {
    height: 100px;
  }
}
</style>
<template>
  <div id="Mchanpin">
    <mtabbarVue />
    <div class="banner flex ALcenter">
      <div class="banner-box">
        <div class="banner-box-tit">智慧社区平台</div>
        <div class="banner-box-text">
          集成社区智能化建设和社区治理、社区服务场景的综合型社区一体化平台
        </div>
        <div class="flex banner-box-box">
          <div class="banner-box-box-shiyong tac" @click="goTijiao">申请试用</div>
          <div class="banner-box-box-yanshi tac" @click="goTijiao">预约演示</div>
        </div>
      </div>
    </div>

    <div class="texing">
      <div class="texing-tit tac">方案特性</div>
      <div class="texing-box flex JCspaceBetween fwW">
        <div class="texing-box-li" v-for="(item, index) in texing" :key="index">
          <div class="texing-box-li-zhan1"></div>
          <div class="texing-box-li-item">
            <img :src="item.icon" class="texing-box-li-item-icon" alt="" />
            <div class="texing-box-li-item-zhan"></div>
            <div class="texing-box-li-item-name tac">{{ item.name }}</div>
            <div class="texing-box-li-item-text tac textTwo">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="gongneng">
      <div class="gongneng-tit tac">产品功能</div>
      <div class="gongneng-tab flex">
        <div
          class="gn-tab-li tac"
          v-for="(item, index) in anyList"
          :key="index"
          @click="switchany(item.id)"
          :class="item.id == anyIdx ? 'gn-tab-liyes' : 'gn-tab-lino'"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="gn-box">
        <div
          class="gn-box-li"
          v-for="(item, index) in gongneng[anyIdx - 1]"
          :key="index"
        >
          <div class="gn-box-li-tiao"></div>
          <div class="gn-box-li-tit flex ALcenter">
            <div class="gn-box-li-tit-left"></div>
            <div>{{ item.name }}</div>
          </div>
          <div class="gn-box-li-text">{{ item.text }}</div>
        </div>
      </div>
    </div>

    <MchangjingVue />
    <MkehuVue />
    <MbottomVue />
  </div>
</template>

<script>
import MchangjingVue from "../components/mcom/Mchangjing.vue";
import { gongneng } from "@/store/datat.js";
import MkehuVue from '../components/mcom/Mkehu.vue';
import mtabbarVue from "@/components/Mtabbar.vue";
import MbottomVue from "@/components/mcom/Mbottom.vue";

export default {
  components: {
    MkehuVue,
    MchangjingVue,
    mtabbarVue,
    MbottomVue,
  },
  data() {
    return {
      texing: [
        {
          name: "全面覆盖",
          text: "云茗科技智慧社区平台功能丰富，覆盖社区治理、服务、安防等全域场景",
          icon: require("@/assets/icon/Hchanpin/cp1.png"),
        },
        {
          name: "产品成熟",
          text: "已成功部署100+个社区稳健运行，服务社区居民，提升社区治理和服务水平",
          icon: require("@/assets/icon/Hchanpin/cp2.png"),
        },
        {
          name: "灵活定制",
          text: "在标准化软件产品和服务的基础上，在PC端、小程序端、大屏端等提供灵活的定制化开发服务",
          icon: require("@/assets/icon/Hchanpin/cp3.png"),
        },
        {
          name: "多端支持",
          text: "支持手机端小程序、电脑端管理后台、和可视化数据大屏。满足各种场景下的系统使用需求",
          icon: require("@/assets/icon/Hchanpin/cp4.png"),
        },
        {
          name: "技术先进",
          text: "采用先进的物联网技术、云计算技术、3D建模等前沿技术，保障平台的高效运转",
          icon: require("@/assets/icon/Hchanpin/cp5.png"),
        },
        {
          name: "持续升级",
          text: "持续不断提供软件产品的升级服务，为用户长期创造价值",
          icon: require("@/assets/icon/Hchanpin/cp6.png"),
        },
      ],
      anyIdx: 1,
      anyList: [
        {
          id: 1,
          name: "社区治理场景",
        },
        {
          id: 2,
          name: "社区服务场景",
        },
        {
          id: 3,
          name: "社区安防建设",
        },
      ],
      anyTime: null,
      gongneng,
    };
  },
  methods: {
    switchany(e) {
      this.anyIdx = e;
    },

    
    /**
     * 提交
     */
     goTijiao() {
      this.$router.push("/Mshenqing");
     },
  },
};
</script>

<style lang="less" scoped>
#Mchanpin {
  width: 750px;

  .banner {
    width: 750px;
    height: 520px;
    background: url(~@/assets/m/chanpin2.png) no-repeat;
    background-size: 100% 100%;
    .banner-box {
      margin-left: 44px;
      .banner-box-tit {
        width: 380px;
        font-weight: 600;
        font-size: 44px;
        color: #353c58;
        line-height: 52px;
        margin-bottom: 24px;
      }
      .banner-box-text {
        width: 380px;
        font-size: 24px;
        color: #64698b;
        line-height: 44px;
        margin-bottom: 24px;
      }

      .banner-box-box {
        .banner-box-box-shiyong {
          width: 192px;
          height: 60px;
          line-height: 60px;
          font-size: 28px;
          color: #ffffff;
          background: #038ef2;
          border-radius: 8px 8px 8px 8px;
          margin-right: 16px;
        }
        .banner-box-box-yanshi {
          width: 192px;
          height: 60px;
          line-height: 60px;
          font-size: 28px;
          color: #038ef2;
          border-radius: 8px 8px 8px 8px;
          border: 2px solid #038ef2;
        }
      }
    }
  }

  .texing {
    width: 750px;
    background: #fff;
    height: 781px;

    .texing-tit {
      width: 750px;
      height: 138px;
      line-height: 138px;
      font-weight: 600;
      font-size: 36px;
      color: #353c58;
    }

    .texing-box {
      width: 702px;
      margin: 0 24px;
      .texing-box-li {
        width: 226px;
        height: 210px;
        margin-bottom: 78px;
        .texing-box-li-zhan1 {
          width: 226px;
          height: 40px;
        }

        .texing-box-li-item {
          width: 226px;
          height: 210px;
          background: #f2f9ff;
          border-radius: 8px;
          position: relative;
          .texing-box-li-item-icon {
            position: absolute;
            width: 80px;
            height: 7280pxpx;
            left: 50%;
            top: -40px;
            transform: translateX(-50%);
          }

          .texing-box-li-item-zhan {
            height: 54px;
          }

          .texing-box-li-item-name {
            width: 226px;
            height: 40px;
            line-height: 40px;
            font-weight: 600;
            font-size: 28px;
            color: #353c58;
          }
          .texing-box-li-item-text {
            width: 184px;
            font-size: 20px;
            color: #64698b;
            line-height: 24px;
            margin: 12px 21px 0;
          }
        }
      }
    }
  }

  .gongneng {
    width: 750px;
    height: 1110px;
    background: #f2f9ff;

    .gongneng-tit {
      width: 750px;
      height: 126px;
      line-height: 126px;
      font-weight: 600;
      font-size: 36px;
      color: #353c58;
    }

    .gongneng-tab {
      width: 702px;
      margin: 0 24px;
      height: 72px;
      background: rgba(3, 142, 242, 0.1);
      border-radius: 8px;
      .gn-tab-li {
        width: 234px;
        height: 72px;
        line-height: 72px;
        border-radius: 8px;
        font-size: 28px;
      }
      .gn-tab-liyes {
        background: #038ef2;
        font-weight: 500;
        color: #ffffff;
      }

      .gn-tab-lino {
        color: #353c58;
      }
    }

    .gn-box {
      width: 702px;
      height: 844px;
      margin: 28px 24px 0;
      background: url(~@/assets/m/chanpin1.png) no-repeat;
      background-size: 100% 100%;

      .gn-box-li {
        width: 642px;
        margin: 0 30px 0;

        .gn-box-li-tiao {
          height: 36px;
        }

        .gn-box-li-tit {
          width: 642px;
          height: 56px;
          font-weight: 500;
          font-size: 28px;
          color: #353c58;
          background: linear-gradient(
            90deg,
            rgba(3, 142, 242, 0.1) 0%,
            rgba(3, 142, 242, 0) 100%
          );
          .gn-box-li-tit-left {
            width: 6px;
            height: 56px;
            margin-right: 16px;
            background: #038ef2;
          }
        }

        .gn-box-li-text {
          width: 642px;
          font-size: 26px;
          color: #64698b;
          line-height: 38px;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
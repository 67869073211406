<template>
  <div id="Habout">
    <div class="banner flex ALcenter JCcenter">
      <div class="banner-box">
        <div class="banner-box-title tac">关于云茗</div>
        <div class="banner-box-text tac">About YunMing</div>
      </div>
    </div>
    <div class="tabbar">
      <div class="tabbar-box flex JCcenter">
        <div
          class="tabbar-box-box fs9 hand-cursor"
          @click="switchIdx(item.id)"
          :class="item.id == idx ? 'lan038 fw6' : 'hui646'"
          v-for="(item, index) in tabbarlist"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="jieshao">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">公司介绍</div>
        <div class="jieshao-box-text tac">Company Profile</div>
        <div class="jieshao-box-big flex JCspaceBetween">
          <div class="jieshao-box-big-left"></div>
          <div class="jieshao-box-big-right">
            <div>
              浙江云茗科技股份有限公司成立于2023年，是一家领先的科技型集团化企业，是专业成熟的智慧城市承建方，自成立以来，公司专注于政企数字化改革领域，凭借着丰富的物联网场景平台开发经验和软硬件集成技术、智能化弱电施工及运维保障，建立了领先的解决方案规划、技术产品设计、项目工程建设等核心能力体系，致力于提供更加智能、高效的城市治理现代化数字解决方案。
            </div>
            <div>
              公司始终以产品为核心、以技术为桥梁、以品质为保障，致力于数字赋能城市治理，推动城市治理能力现代化，打造数字化改革先进范本。致力于让城市管理更加高效、更加便捷、更加智慧！
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="licheng jieshao">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">发展历程</div>
        <div class="jieshao-box-text tac">Development History</div>
        <div class="jieshao-box-licheng flex JCcenter">
          <div
            class="jieshao-box-licheng-box"
            v-for="(item, index) in licheng"
            :key="index"
          >
            <div class="jieshao-box-licheng-box-tit">{{ item.time }}</div>
            <div class="jieshao-box-licheng-box-tit-yuan">
              <div class="jieshao-box-licheng-box-tit-yuan-dian"></div>
            </div>
            <div class="jieshao-box-licheng-box-text">{{ item.text }}</div>
          </div>
          <div class="jieshao-box-licheng-tiao"></div>
        </div>
      </div>
    </div>
    <pingpairongyuzizhi />
    <zhishichanquan />
    <div class="dongtai">
      <div class="jieshao-zhan1"></div>
      <div class="jieshao-box">
        <div class="jieshao-box-title tac">公司动态</div>
        <div class="jieshao-box-text tac">Company Dynamics</div>
        <div class="jieshao-box-fl flex JCspaceBetween fwW">
          <div
            class="jieshao-box-fl-item flex"
            v-for="(item, index) in dongtai"
            :key="index"
          >
            <img class="jieshao-box-fl-item-img" :src="item.icon" alt="" />
            <div class="jieshao-box-fl-item-box">
              <div class="jieshao-box-fl-item-box-tit textOne">
                {{ item.name }}
              </div>
              <div class="jieshao-box-fl-item-box-text textTwo4">
                {{ item.text }}
              </div>
              <div class="jieshao-box-fl-item-box-time">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pingpairongyuzizhi from "../components/pingpairongyuzizhi.vue";
import zhishichanquan from "../components/zhishichanquan.vue";
export default {
  name: "Habout",
  components: {
    pingpairongyuzizhi,
    zhishichanquan,
  },
  data() {
    return {
      idx: 1,
      tabbarlist: [
        {
          id: 1,
          name: "公司介绍",
        },
        {
          id: 2,
          name: "发展历程",
        },
        {
          id: 3,
          name: "权威资质",
        },
        {
          id: 4,
          name: "知识产权",
        },
        {
          id: 5,
          name: "公司动态",
        },
      ],
      licheng: [
        {
          time: "2023年7月",
          text: "公司成立",
        },
        {
          time: "2024年4月",
          text: "网络部成立，业务拓展包括园区企业网络建设及家庭宽带安装办理。",
        },
        // {
        //   time: "2024年5月",
        //   text: "云茗科技与墨非天时签订战略合作协议",
        // },
        {
          time: "2024年7月",
          text: "公司成立一周年",
        },
      ],
      dongtai: [
        {
          id: 1,
          name: "云茗科技成立一周年",
          text: "七月流火，在这星光璀璨的夜晚，我们欢聚一堂，共庆云茗一周年华诞。俗话说，“一年好景君须记，最是橙黄橘绿时“。一年前，我们带着梦想和希望，扬帆起航；一年后，我们满载成就与荣耀，共襄盛举。今晚，让我们以这一年的奋斗为起点，激发我们的潜力，点燃我们的激情，向着更高的目标，勇往直前！",
          time: "2024.7.19",
          icon: require("@/assets/bg/dongtai4.png"),
        },
        {
          id: 1,
          name: "创新发展 智驱未来｜云茗科技与墨非天时签订战略合作协议",
          text: "5月10日下午，浙江云茗科技股份有限公司（以下简称“云茗科技”）与墨非天时布业（杭州）有限公司（以下简称“墨非天时”）正式达成战略合作。在签约仪式现场云茗科技总经理钟总，墨非天时总经理周总代表各自企业签署了战略合作协议。...",
          time: "2024.05.10",
          icon: require("@/assets/bg/dongtai1.png"),
        },
        {
          id: 1,
          name: "开工大吉 | 华章新启，共赴新征程！",
          text: "新年新气象，新年新作为，2024年2月19日，甲辰年正月初十，云茗科技开工第一天，满怀对新年的无限热忱和期待从热闹喜庆的假期模式迅速切换到奋进忙碌的工作状态，只争朝夕的干事热情昂扬向上的奋进姿态全身心投入到公司的各项工作中开启崭新一年的奋斗，以满怀的信心迎接新的机遇和挑战。",
          time: "2024.2.19",
          icon: require("@/assets/bg/dongtai2.png"),
        },
        {
          id: 1,
          name: "年会盛典 | 云茗科技2023年会盛典顺利召开！",
          text: "杭州云茗科技有限公司2023年会盛典顺利召开，云茗科技公司各位领导、各部门负责人及全体员工出席会议，大会以“同心致远 共筑云茗”为主题，会上回顾总结了2023年度公司的发展情况，同时部署规划2024年经营目标，吹响新的集结号。",
          time: "2024.1.18",
          icon: require("@/assets/bg/dongtai3.png"),
        },
      ],
    };
  },
  methods: {
    switchIdx(e) {
      this.idx = e;
      if (e == 1) {
        this.scrollToTop(420);
      } else if (e == 2) {
        this.scrollToTop(853);
      } else if (e == 3) {
        this.scrollToTop(1260);
      } else if (e == 4) {
        this.scrollToTop(2260);
      } else if (e == 5) {
        this.scrollToTop(3220);
      }


    },

    scrollToTop(e) {
      window.scrollTo({
        top: e,
        behavior: "smooth", // 平滑滚动
      });
    },
  },
};
</script>

<style lang="less" scoped>
#Habout {
  .banner {
    width: 1920px;
    height: 420px;
    background: url(~@/assets/bg/Habout.png) no-repeat;
    background-size: 100% 100%;
    .banner-box {
      width: 1920px;
      .banner-box-title {
        width: 1920px;
        height: 59px;
        font-size: 42px;
        color: #353c58;
        line-height: 59px;
        margin-bottom: 12px;
      }

      .banner-box-text {
        width: 1920px;
        height: 22px;
        font-size: 18px;
        color: #64698b;
        line-height: 22px;
        letter-spacing: 2px;
      }
    }
  }

  .tabbar {
    width: 1920px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.25);
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 4px;

    .tabbar-box {
      width: 1440px;
      height: 80px;
      margin: 0 240px;

      .tabbar-box-box {
        height: 80px;
        line-height: 80px;
        margin-right: 230px;
      }
      .tabbar-box-box:last-child {
        margin-right: 0;
      }
    }
  }

  .jieshao {
    width: 1920px;
    height: 433px;
    background: #f7fcff;

    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 19px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 24px;
        font-size: 14px;
        color: #64698b;
      }

      .jieshao-box-big {
        width: 1440px;
        .jieshao-box-big-left {
          width: 86px;
          height: 72px;
          background: url(~@/assets/icon/about1.png) no-repeat;
          background-size: 100% 100%;
        }

        .jieshao-box-big-right {
          width: 1324px;
          font-size: 16px;
          color: #353c58;
          line-height: 30px;
          margin-top: 35px;
        }
      }
    }
  }

  .licheng {
    width: 1920px;
    height: 449px;
    background: url(~@/assets/bg/Hlicheng.png) no-repeat;
    background-size: 100% 100%;

    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 19px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 68px;
        font-size: 14px;
        color: #64698b;
      }
      .jieshao-box-licheng {
        width: 1440px;
        position: relative;
        .jieshao-box-licheng-box {
          width: 264px;
          margin-right: 95px;

          .jieshao-box-licheng-box-tit {
            width: 264px;
            font-weight: 600;
            font-size: 24px;
            color: #038ef2;
            height: 34px;
            margin-bottom: 16px;
          }

          .jieshao-box-licheng-box-tit-yuan {
            width: 264px;
            height: 16px;
            .jieshao-box-licheng-box-tit-yuan-dian {
              width: 16px;
              height: 16px;
              background: #038ef2;
              border-radius: 50%;
              margin-left: 10px;
            }
          }

          .jieshao-box-licheng-box-text {
            margin-top: 27px;
            width: 264px;
            font-weight: 500;
            font-size: 16px;
            color: #64698b;
            line-height: 30px;
          }
        }
        .jieshao-box-licheng-box:last-child {
          margin-right: 0 !important;
        }

        .jieshao-box-licheng-tiao {
          position: absolute;
          left: 0;
          top: 57px;
          width: 1440px;
          height: 2px;
          background: linear-gradient(
            90deg,
            rgba(3, 142, 242, 0.2) 0%,
            #038ef2 4%,
            #038ef2 89%,
            rgba(3, 142, 242, 0.2) 100%
          );
        }
      }
    }
  }

  .dongtai {
    width: 1920px;
    height: 748px;
    background: #fff;
    .jieshao-zhan1 {
      width: 1920px;
      height: 64px;
    }
    .jieshao-box {
      width: 1440px;
      margin: 0 240px;
      .jieshao-box-title {
        width: 1440px;
        height: 40px;
        margin-bottom: 19px;
        font-weight: 600;
        font-size: 28px;
        color: #353c58;
      }

      .jieshao-box-text {
        width: 1440px;
        height: 17px;
        margin-bottom: 68px;
        font-size: 14px;
        color: #64698b;
      }

      .jieshao-box-fl {
        width: 1440px;
        .jieshao-box-fl-item {
          width: 700px;
          height: 244px;
          background: #ffffff;
          box-shadow: 6px 6px 8px 0px rgba(223, 232, 238, 0.3);
          border: 1px solid #dfe8ee;
          margin: 0 40px 40px 0;

          .jieshao-box-fl-item-img {
            width: 260px;
            height: 196px;
            margin: 24px 20px 0 24px;
          }

          .jieshao-box-fl-item-box {
            width: 346px;
            margin-top: 24px;
            .jieshao-box-fl-item-box-tit {
              width: 346px;
              height: 22px;
              line-height: 22px;
              font-weight: 600;
              font-size: 16px;
              color: #353c58;
              margin-bottom: 16px;
            }

            .jieshao-box-fl-item-box-text {
              width: 346px;
              height: 22px;
              font-size: 14px;
              color: #64698b;
              line-height: 22px;
              margin-bottom: 28px;
              height: 88px;
            }

            .jieshao-box-fl-item-box-time {
              width: 346px;
              height: 22px;
              font-size: 14px;
              color: #64698b;
              line-height: 22px;
            }
          }
        }

        .jieshao-box-fl-item:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
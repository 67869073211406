import Vue from 'vue'
import VueRouter from 'vue-router'
import Homes from '@/views/Homes.vue'
import Hanli from '@/views/Hanli.vue'
import Habout from '@/views/Habout.vue'
import Hfangan from '@/views/Hfangan.vue'
import Hchanpin from '@/views/Hchanpin.vue'
import Mhome from '@/views/Mhome.vue'
import Mchanpin from '@/views/Mchanpin.vue'
import Manli from '@/views/Manli.vue'
import Mfangan from '@/views/Mfangan.vue'
import Mabout from '@/views/Mabout.vue'
import Mshenqing from '@/views/Mshenqing.vue'
import MnewsDetal from '@/views/MnewsDetal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Homes',
    component: Homes,
    meta: {
      title: '首页',
      show: true
    }
  },
  {
    path: '/Hanli',
    name: 'Hanli',
    component: Hanli,
    meta: {
      title: '经典案例',
      show: true
    }
  },
  {
    path: '/Hchanpin',
    name: 'Hchanpin',
    component: Hchanpin,
    meta: {
      title: '产品与服务',
      show: true
    }
  },
  {
    path: '/Hfangan',
    name: 'Hfangan',
    component: Hfangan,
    meta: {
      title: '解决方案',
      show: true
    }
  },
  {
    path: '/Habout',
    name: 'Habout',
    component: Habout,
    meta: {
      title: '关于我们',
      show: true
    }
  },
  // mhome
  {
    path: '/Mhome',
    name: 'Mhome',
    component: Mhome,
    meta: {
      title: '首页',
      show: true
    }
  },
  {
    path: '/Mchanpin',
    name: 'Mchanpin',
    component: Mchanpin,
    meta: {
      title: '智慧社区平台',
      show: true
    }
  },
  {
    path: '/Manli',
    name: 'Manli',
    component: Manli,
    meta: {
      title: '经典案例',
      show: true
    }
  },
  {
    path: '/Mfangan',
    name: 'Mfangan',
    component: Mfangan,
    meta: {
      title: '智慧社区解决方案',
      show: true
    }
  },
  {
    path: '/Mabout',
    name: 'Mabout',
    component: Mabout,
    meta: {
      title: '关于我们',
      show: true
    }
  },
  {
    path: '/Mshenqing',
    name: 'Mshenqing',
    component: Mshenqing,
    meta: {
      title: '提交',
      show: true
    }
  },
  {
    path: '/MnewsDetal',
    name: 'MnewsDetal',
    component: MnewsDetal,
    meta: {
      title: '动态详情',
      show: true
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//在 router/index.js 增加
router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      router.push({ path: '/Mhome', });
    }
  }
  next();
});

export default router

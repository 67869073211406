<template>
  <div id="MnewsDetal">
    <div class="tiao"></div>
    <div class="tit flex ALcenter">
      <div>
        <div class="tit-name">
          创新发展 智驱未来｜云茗科技与墨非天时签订战略合作协议
        </div>
        <div class="tit-time">发布时间：2022.06.13 16:59:56</div>
      </div>
    </div>
    <div class="box">
      <div class="text">
        5月10日下午，浙江云茗科技股份有限公司（以下简称“云茗科技”）与墨非天时布业（杭州）有限公司（以下简称“墨非天时”）正式达成战略合作。在签约仪式现场云茗科技总经理钟总，墨非天时总经理周总代表各自企业签署了战略合作协议。云茗科技和墨非天时之间的合作，是技术和产业融合的必然路径，是双方纵深推动企业高质量发展的关键方式，也是双方着眼于全球视野，紧抓发展机遇和质量，为智慧园区的建设与发展做出贡献。
      </div>
      <div class="text">
        在双方座谈环节，云茗科技总经理钟总首先对墨非天时一行的到来表示欢迎，并对双方的合作提出更高的期待。他表示，随着信息技术的发展和普及，网络已经成为了现代公司园区不可或缺的基础设施之一。良好的网络连接不仅能提升员工工作效率，还能增加园区的安全性和管理效率。网络维护也成为了园区管理中的重中之重。云茗科技以人工智能、大数据、云计算等领先的技术能力赋能智慧园区建设，构建了数智图景，希望双方能在园区网络运维、数字治理等专业领域，促进智慧园区的网络安全与管理效率。同时深化人工智能技术应用，推动城市治理能力现代化。同时积极响应国家科创需求，深化合作生态，与墨非天时一起推动数字园区的建设与发展。
      </div>
      <div class="text">
        此次的战略合作，代表着云茗科技与墨非天时在园区网络运维与数字技术等方面，将迎来一个里程碑式的跨越，相信通过双方携手共同努力、精诚合作，必将开启高速发展的新篇章！
      </div>
    </div>

    <MbottomVue />
  </div>
</template>

<script>
import MbottomVue from "../components/mcom/Mbottom.vue";
export default {
  components: {
    MbottomVue,
  },
  data() {
    return {};
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
  }
};
</script>

<style lang="less" scoped>
#MnewsDetal {
  width: 750px;
  .tiao {
    width: 750px;
    height: 4px;
    background: #f5f5f5;
  }
  .tit {
    width: 690px;
    margin: 0 30px;
    height: 222px;
    border-bottom: 2px solid #f5f5f5;
    margin-bottom: 40px;
    .tit-name {
      width: 690px;
      font-weight: 600;
      font-size: 32px;
      color: #353c58;
      line-height: 48px;
      margin-bottom: 20px;
    }

    .tit-time {
      font-size: 24px;
      color: #64698b;
      line-height: 28px;
    }
  }

  .box {
    width: 690px;
    margin: 0 30px;

    .text {
      width: 690px;
      font-size: 28px;
      color: #353c58;
      line-height: 56px;
      margin-bottom: 40px;
      text-indent: 2em;
    }
  }
}
</style>
<template>
  <div id="Mabout">
    <mtabbarVue />
    <div class="banner flex JCcenter ALcenter">
      <div>
        <div class="banner-tit tac">关于云茗科技</div>
        <div class="banner-text tac">About YunMing Technology</div>
      </div>
    </div>
    <MaboutVue />

    <MzizhiVue />
    
    <MrongyuVue />


    <MnewsVue />
    <MbottomVue />
  </div>
</template>

<script>
import MaboutVue from '../components/mcom/Mabout2.vue';
import MnewsVue from '../components/mcom/Mnews.vue';
import MrongyuVue from '../components/mcom/Mrongyu.vue';
import MzizhiVue from '../components/mcom/Mzizhi.vue';
import mtabbarVue from "@/components/Mtabbar.vue";
import MbottomVue from "@/components/mcom/Mbottom.vue";
export default {
  name: "Mabout",
  components: {
    MaboutVue,
    MnewsVue,
    MrongyuVue,MzizhiVue,
    mtabbarVue,
    MbottomVue,
  },
};
</script>

<style lang="less" scoped>
#Mabout {
  width: 750px;
  .banner {
    width: 750px;
    height: 340px;
    background: url(~@/assets/m/about3.png) no-repeat;
    background-size: 100% 100%;
    .banner-tit {
      width: 750px;
      font-weight: 600;
      font-size: 44px;
      color: #353c58;
      line-height: 64px;
      letter-spacing: 1px;
      margin-bottom: 12px;
    }
    .banner-text {
      width: 750px;
      font-size: 20px;
      color: #64698b;
      line-height: 23px;
      letter-spacing: 1px;
    }
  }
}
</style>
<template>
  <div id="Homefun">
    <div class="title">
      <div class="zhan1"></div>
      <div class="title-tit tac">
        云茗科技 · 专业成熟的智慧城市承建方与服务商
      </div>
      <div class="title-text tac">
        致力于提供更加智能、高效的城市治理现代化 数字解决方案
      </div>
    </div>
    <div class="box">
      <div class="tab flex">
        <div
          class="tab-item hand-cursor"
          v-for="(item, index) in list"
          :key="index"
          @click="switchidx(item.id)"
        >
          <div
            :class="item.id == idx ? 'tab-item-bghave' : 'tab-item-bgno'"
          ></div>
          <img
            class="tab-item-poaimg"
            v-show="item.id == idx"
            :src="item.poaimg"
            alt=""
          />
          <div class="tab-item-poabox">
            <div class="tab-item-poabox-item flex ALcenter JCcenter">
              <img
                class="tab-item-poabox-item-img"
                :src="item.id == idx ? item.icon2 : item.icon1"
                alt=""
              />
              <div :class="item.id == idx ? 'white fw6 fs8' : 'fs8 fw6 hui353'">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="funs flex JCspaceBetween">
        <div class="funsleft">
          <div class="funsleft-tit flex ALcenter">
            <div class="fs8 fw6 hui353">{{ homefun[idx - 1].name }}</div>
            <div class="funsleft-tit-hot tac">HOT</div>
          </div>
          <div class="funsleft-taxt flex">
            <div class="funsleft-taxt-img"></div>
            <div class="funsleft-taxt-box">
              <div class="funsleft-taxt-box-title">
                {{ homefun[idx - 1].text1 }}
              </div>
              <div class="funsleft-taxt-box-text">
                {{ homefun[idx - 1].text2 }}
              </div>
              <div class="funsleft-taxt-box-look flex ALcenter">
                <div>了解详情</div>
                <div class="funsleft-taxt-box-look-icon"></div>
              </div>
            </div>
          </div>
          <img class="funsleft-poa" :src="homefun[idx - 1].icon" alt="" />
        </div>
        <div class="funsright">
          <div class="funsright-title">{{ homefun1[idx - 1].name }}</div>
          <div
            class="funsright-li flex"
            v-for="(item, index) in homefun1[idx - 1].child"
            :key="index"
          >
            <div
              class="funsright-li-item flex"
              v-for="(childitem, index2) in item"
              :key="index2"
            >
              <img
                class="funsright-li-item-icon"
                :src="childitem.icon"
                alt=""
              />
              <div class="funsright-li-item-box">
                <div class="funsright-li-item-box-tit">
                  {{ childitem.name }}
                </div>
                <div class="funsright-li-item-box-text">
                  {{ childitem.text }}
                </div>
                <div class="flex funsright-li-item-box-btn ALcenter hand-cursor">
                  <div class="fs7 lan038">了解详情</div>
                  <div class="funsright-li-item-box-btn-icon"></div>
                </div>
              </div>
            </div>
          </div>
          <img v-if="idx == 1 || idx == 4" :src="homefun1[idx - 1].icon" class="funsright-poa" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homefun, homefun1 } from "@/store/datat.js";
export default {
  name: "Homefun",
  data() {
    return {
      idx: 1,
      list: [
        {
          id: 1,
          name: "智慧社区平台",
          icon1: require("@/assets/icon/home1.png"),
          icon2: require("@/assets/icon/home5.png"),
          poaimg: require("@/assets/icon/home9.png"),
        },
        {
          id: 2,
          name: "智慧城市物联感知（AIOT）平台",
          icon1: require("@/assets/icon/home2.png"),
          icon2: require("@/assets/icon/home6.png"),
          poaimg: require("@/assets/icon/home10.png"),
        },
        {
          id: 3,
          name: "智慧园区网络建设",
          icon1: require("@/assets/icon/home3.png"),
          icon2: require("@/assets/icon/home7.png"),
          poaimg: require("@/assets/icon/home11.png"),
        },
        {
          id: 4,
          name: "个性化定制服务",
          icon1: require("@/assets/icon/home4.png"),
          icon2: require("@/assets/icon/home8.png"),
          poaimg: require("@/assets/icon/home12.png"),
        },
      ],
      homefun,
      homefun1,
    };
  },

  methods: {
    switchidx(e) {
      this.idx = e;
    },
  },
};
</script>

<style lang="less" scoped>
#Homefun {
  width: 1920px;
  height: 820px;
  background: url(~@/assets/bg/home1.png) no-repeat;
  background-size: 100% 100%;

  .title {
    width: 1440px;
    height: 171px;
    margin: 0 240px;
    .zhan1 {
      width: 1440px;
      height: 64px;
    }
    .title-tit {
      width: 1440px;
      height: 39px;
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 18px;
      color: #353c58;
    }

    .title-text {
      width: 1440px;
      font-size: 14px;
      color: #64698b;
    }
  }

  .box {
    width: 1440px;
    margin: 0 240px;
    .tab {
      width: 1440px;
      height: 60px;
      box-shadow: 0px 4px 12px 0px rgba(166, 166, 166, 0.15);
      background: #fff;

      .tab-item {
        width: 360px;
        height: 60px;
        position: relative;
        .tab-item-bghave {
          position: absolute;
          left: 0;
          top: 0;
          width: 360px;
          height: 60px;
          z-index: 1;
          background: #038ef2;
        }
        .tab-item-bgno {
          position: absolute;
          left: 0;
          top: 0;
          width: 360px;
          height: 60px;
          z-index: 1;
        }

        .tab-item-poaimg {
          position: absolute;
          left: 0;
          top: 0;
          width: 360px;
          height: 60px;
          z-index: 2;
        }

        .tab-item-poabox {
          position: absolute;
          left: 0;
          top: 0;
          width: 360px;
          height: 60px;
          z-index: 3;
          .tab-item-poabox-item {
            width: 360px;
            height: 60px;
            .tab-item-poabox-item-img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .funs {
      width: 1440px;
      height: 533px;
      margin-top: 16px;
      .funsleft {
        width: 336px;
        height: 533px;
        background: url(~@/assets/bg/home4.png) no-repeat;
        background-size: 100% 100%;
        background-color: #fff;
        border-radius: 16px;
        position: relative;

        .funsleft-tit {
          width: 302px;
          margin: 20px 32px 44px;
          height: 22px;

          .funsleft-tit-hot {
            width: 36px;
            height: 20px;
            line-height: 20px;
            background: rgba(249, 108, 91, 0.1);
            margin-left: 8px;
            color: #f96c5b;
            font-size: 12px;
          }
        }

        .funsleft-taxt {
          width: 302px;
          margin: 0 32px;
          position: relative;
          .funsleft-taxt-img {
            width: 60px;
            height: 60px;
            margin-right: 16px;
            background: url(~@/assets/icon/homei001.png) no-repeat;
            background-size: 100% 100%;
          }

          .funsleft-taxt-box {
            width: 196px;
            .funsleft-taxt-box-title {
              width: 196px;
              height: 30px;
              font-weight: 600;
              font-size: 16px;
              color: #353c58;
            }

            .funsleft-taxt-box-text {
              width: 196px;
              font-size: 14px;
              color: #64698b;
              line-height: 22px;
            }

            .funsleft-taxt-box-look {
              width: 196px;
              height: 20px;
              margin-top: 8px;
              font-size: 14px;
              color: #038ef2;
              .funsleft-taxt-box-look-icon {
                width: 10px;
                height: 10px;
                background: url(~@/assets/icon/arr3.png) no-repeat;
                background-size: 100% 100%;
                margin-left: 4px;
              }
            }
          }
        }

        .funsleft-poa {
          width: 201px;
          height: 180px;
          position: absolute;
          right: 15px;
          bottom: 24px;
        }
      }

      .funsright {
        width: 1084px;
        height: 533px;
        background: url(~@/assets/bg/home5.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 16px;
        background: #fff;
        position: relative;
        .funsright-title {
          width: 1020px;
          margin: 20px 32px 0;
          height: 22px;
          font-weight: 600;
          font-size: 16px;
          color: #353c58;
        }

        .funsright-li {
          width: 1020px;
          margin: 44px 32px 0;
          .funsright-li-item {
            width: 310px;
            margin-right: 44px;
            .funsright-li-item-icon {
              width: 60px;
              height: 60px;
              margin-right: 16px;
            }

            .funsright-li-item-box {
              width: 234px;
              .funsright-li-item-box-tit {
                width: 234px;
                height: 20px;
                font-weight: 600;
                font-size: 16px;
                color: #353c58;
                margin-bottom: 8px;
              }
            }
          }
          .funsright-li-item:last-child {
            margin-right: 0;
          }

          .funsright-li-item-box-text {
            width: 234px;
            font-size: 14px;
            color: #64698b;
            margin-bottom: 8px;
            line-height: 22px;
          }

          .funsright-li-item-box-btn {
            width: 234px;
            height: 20px;
            .funsright-li-item-box-btn-icon {
              width: 10px;
              height: 10px;
              background: url(~@/assets/icon/arr3.png) no-repeat;
              background-size: 100% 100%;
              margin-left: 4px;
            }
          }
        }

        .funsright-poa {
          position: absolute;
          width: 200px;
          height: 180px;
          right: 16px;
          bottom: 23px;
        }
      }
    }
  }
}
</style>
<template>
  <div id="Rongyu">
    <div class="title tac">权威资质与知识产权</div>
    <div class="tab flex ALcenter JCcenter">
      <div class="tab-li" v-for="(item, index) in tab" :key="index">
        <div
          class="tab-li-box tac fs8 hand-cursor"
          :class="idx == item.id ? 'lan038 fw6' : 'hui353'"
          @click="switchtab(item.id)"
        >
          {{ item.name }}
        </div>
        <div class="tab-li-tiao" v-show="idx == item.id"></div>
      </div>
    </div>
    <div class="imgbox flex fwW" v-show="idx == 1">
      <div class="imgbox-li" v-for="(item, index) in zizhi" :key="index">
        <img
          :src="item.icon"
          :class="item.fang ? 'imgbox-li-fang' : 'imgbox-li-chang'"
          alt=""
        />
        <div
          class="flex ALcenter"
          :class="item.fang ? 'imgbox-li-fangtext' : 'imgbox-li-changtext'"
        >
          <div class="imgbox-li-fangtext-text tac">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="imgbox flex fwW" v-show="idx == 2">
      <div class="imgbox-li" v-for="(item, index) in ruanzhu" :key="index">
        <img
          :src="item.icon"
          :class="item.fang ? 'imgbox-li-fang' : 'imgbox-li-chang'"
          alt=""
        />
        <div
          class="flex ALcenter"
          :class="item.fang ? 'imgbox-li-fangtext' : 'imgbox-li-changtext'"
        >
          <div class="imgbox-li-fangtext-text tac">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { zizhi, ruanzhu } from "@/store/datat.js";
export default {
  name: "Rongyu",
  data() {
    return {
      idx: 1,
      tab: [
        {
          id: 1,
          name: "权威资质",
        },
        {
          id: 2,
          name: "知识产权",
        },
      ],
      zizhi,
      ruanzhu,
    };
  },
  methods: {
    switchtab(e) {
      this.idx = e;
    },
  },
};
</script>

<style lang="less" scoped>
#Rongyu {
  width: 1920px;
  height: 978px;
  background: url(~@/assets/bg/rongyu1.png) no-repeat;
  background-size: 100% 100%;
  .title {
    width: 1920px;
    height: 148px;
    line-height: 148px;
    font-weight: 600;
    font-size: 28px;
    color: #353c58;
  }

  .tab {
    width: 1440px;
    height: 42px;
    border-bottom: 1px solid #e6ecf3;
    margin: 0 240px;
    .tab-li {
      width: 240px;
      height: 42px;
      position: relative;
      .tab-li-box {
        width: 240px;
        height: 24px;
        line-height: 24px;
      }

      .tab-li-tiao {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 240px;
        height: 4px;
        background: #038ef2;
      }
    }
  }

  .imgbox {
    width: 1440px;
    // height: 707px;
    margin: 41px 240px 24px;
    font-size: 0;

    .imgbox-li {
      width: 225px;
      height: 225px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      margin-right: 18px;
      margin-bottom: 16px;

      .imgbox-li-fang {
        width: 193px;
        height: 140px;
        margin: 23px 16px 0;
      }
      .imgbox-li-fangtext {
        width: 193px;
        margin: 0 16px;
        height: 60px;

        .imgbox-li-fangtext-text {
          width: 193px;
          font-size: 14px;
          color: #353c58;
          line-height: 16px;
        }
      }

      .imgbox-li-chang {
        width: 110px;
        height: 155px;
        margin: 16px 57px 0;
      }
      .imgbox-li-changtext {
        width: 193px;
        margin: 0 16px;
        height: 54px;
        .imgbox-li-fangtext-text {
          width: 193px;
          font-size: 14px;
          color: #353c58;
          line-height: 16px;
        }
      }
    }
    .imgbox-li:nth-child(6n) {
      margin-right: 0;
    }
  }
}
</style>
import { render, staticRenderFns } from "./Mkehu.vue?vue&type=template&id=24536d2d&scoped=true"
import script from "./Mkehu.vue?vue&type=script&lang=js"
export * from "./Mkehu.vue?vue&type=script&lang=js"
import style0 from "./Mkehu.vue?vue&type=style&index=0&id=24536d2d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24536d2d",
  null
  
)

export default component.exports
<template>
  <div id="Dialogbox" v-show="show" @click="closePopup">
    <div class="box flex" @click.stop="buttonClicked">
      <div class="left"></div>
      <div class="right" v-if="idx == 1">
        <div class="right-tit tac">智慧物联连接世界 · 共创未来美好生活</div>
        <div class="right-text tac">提交信息，工作人员将在24小时内与您联系</div>
        <div class="right-forms">
          <div class="forms-bi flex ALcenter">
            <div class="forms-bi-left tac">*</div>
            <input
              type="text"
              :value="obj.gongsi"
              placeholder="请输入公司全称"
              class="forms-bi-right"
            />
          </div>
          <div class="forms-bi flex ALcenter">
            <div class="forms-bi-left tac ">*</div>
            <input
              type="text"
              :value="obj.xingming"
              placeholder="请输入姓名"
              class="forms-bi-right"
            />
          </div>
          <div class="forms-bi flex ALcenter">
            <div class="forms-bi-left tac">*</div>
            <input
              type="text"
              :value="obj.zhiwei"
              placeholder="请输入职位"
              class="forms-bi-right"
            />
          </div>
          <div class="forms-bi flex ALcenter">
            <div class="forms-bi-left tac">*</div>
            <input
              type="text"
              maxlength="11"
              :value="obj.shoji"
              placeholder="请输入手机号"
              class="forms-bi-right"
            />
          </div>
          <div class="forms-bi flex ALcenter">
            <div class="forms-bi-left tac"></div>
            <input
              type="text"
              :value="obj.miaoshu"
              placeholder="请输入需求描述（选填）"
              class="forms-bi-right"
            />
          </div>

          <!-- 提交和切换 -->
          <div class="forms-submit tac hand-cursor" @click="tijiao">确定</div>
          <div
            class="forms-switch flex ALcenter JCcenter hand-cursor"
            @click="qiehuan(idx)"
          >
            <div class="forms-switch-left"></div>
            <div class="forms-switch-text tac">微信咨询</div>
            <div class="forms-switch-right"></div>
          </div>
        </div>
      </div>
      <div class="right" v-if="idx == 2">
        <div class="right-weixin flex ALcenter JCcenter">
          <div class="right-weixin-icon"></div>
          <div class="right-weixin-text">关注微信公众号</div>
        </div>
        <img class="right-img" :src="icon" alt="" />
        <div class="right-forms">
          <div
            class="forms-switch flex ALcenter JCcenter hand-cursor"
            @click="qiehuan(idx)"
          >
            <div class="forms-switch-left"></div>
            <div class="forms-switch-text tac">电话咨询</div>
            <div class="forms-switch-right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dialogbox",
  data() {
    return {
      show: false,
      idx: 1,
      obj: {
        gongsi: null,
        xingming: null,
        zhiwei: null,
        shoji: null,
        miaoshu: null,
      },
      icon: require("@/assets/icon/weixin.png"),
    };
  },
  // 计算属性+监听器
  computed: {
    changedialogshow() {
      return this.$store.state.dialogshow;
    },
  },
  watch: {
    changedialogshow: {
      handler(newValue, oldValue) {
        this.show = newValue;
        console.log(newValue, oldValue);
      },
    },
  },

  //   mounted() {
  //     // 添加点击事件监听
  //     window.addEventListener("click", this.handleClickOutside);
  //   },
  //   beforeDestroy() {
  //     // 组件销毁前移除事件监听
  //     window.removeEventListener("click", this.handleClickOutside);
  //   },

  methods: {
    // handleClickOutside(event) {
    //   // 如果点击发生在弹出层外部，则关闭弹出层
    //   if (
    //     !this.$refs.dialogbox ||
    //     !this.$refs.dialogbox.contains(event.target)
    //   ) {
    //     // 执行关闭弹出层的逻辑，例如隐藏弹出层
    //     this.closePopup();
    //   }
    // },
    closePopup() {
      // 关闭弹出层的代码
      // console.log("关闭");
      this.$store.state.dialogshow = false;
      this.show = false;
    },
    tijiao() {
      // console.log("关闭");
      this.$message({
        message: "提交成功",
        type: "success",
      });
      this.$store.state.dialogshow = false;
      this.show = false;
    },

    qiehuan(e) {
      if (e == 1) {
        this.idx = 2;
        this.qingkong();
      } else {
        this.idx = 1;
      }
    },

    qingkong() {
      this.obj = {
        gongsi: null,
        xingming: null,
        zhiwei: null,
        shoji: null,
        miaoshu: null,
      };
    },

    buttonClicked() {
        // console.log('组织了冒泡');
    },
  },
};
</script>

<style lang="less" scoped>
#Dialogbox {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    width: 1120px;
    height: 600px;
    background: #ffff;
    border-radius: 16px;
    .left {
      width: 528px;
      height: 600px;
      background: url(~@/assets/bg/dialog1.png) no-repeat;
      background-size: 100% 100%;
    }

    .right {
      width: 592px;
      height: 600px;
      .right-tit {
        width: 592px;
        height: 34px;
        margin: 40px 0 8px 0;
        font-weight: 600;
        font-size: 24px;
        color: #353c58;
        letter-spacing: 1px;
      }

      .right-text {
        width: 592px;
        height: 20px;
        font-size: 14px;
        color: #64698b;
        margin-bottom: 24px;
      }

      .right-forms {
        width: 436px;
        margin: 0 78px;
        .forms-bi {
          width: 436px;
          height: 48px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid rgba(100, 105, 139, 0.2);
          margin-bottom: 10px;
          .forms-bi-left {
            width: 36px;
            color: #ff3f3f;
            line-height: 16px;
            font-size: 16px;
          }

          .forms-bi-right {
            width: 364px;
            font-size: 14px;
            color: #64698b;
            border: 0;
            margin: 0;
            padding: 0;
          }
          .forms-bi-right:focus {
            outline: none;
          }
        }

        .forms-bi:last-child {
          margin-bottom: 0;
        }

        .forms-submit {
          width: 436px;
          height: 48px;
          line-height: 48px;
          background: #038ef2;
          border-radius: 4px 4px 4px 4px;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          margin-top: 24px;
        }

        .forms-switch {
          width: 436px;
          height: 20px;
          margin-top: 16px;

          .forms-switch-left {
            width: 122px;
            height: 2px;
            background: url(~@/assets/icon/dialogleft.png) no-repeat;
            background-size: 100% 100%;
          }

          .forms-switch-text {
            width: 80px;
            font-size: 14px;
            color: #64698b;
          }

          .forms-switch-right {
            width: 122px;
            height: 2px;
            background: url(~@/assets/icon/dialogright.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .right-weixin {
        width: 360px;
        margin: 96px 116px 44px;
        height: 34px;
        .right-weixin-icon {
          width: 30px;
          height: 24px;
          background: url(~@/assets/icon/weixinicon.png) no-repeat;
          background-size: 100% 100%;
          margin-right: 10px;
        }

        .right-weixin-text {
          font-weight: 600;
          font-size: 24px;
          color: #353c58;
          line-height: 28px;
        }
      }

      .right-img {
        width: 284px;
        height: 284px;
        margin: 0 154px 36px;
      }
    }
  }
}
</style>
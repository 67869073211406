<template>
  <div id="app" :class="browserWidth < 1800 ? '' : 'nowidthx'">
    <tabbar v-if="detect == 'pc'" />
    <router-view v-if="detect == 'pc'" />
    <bottom v-if="detect == 'pc'" />

    <!-- 移动端 -->
    <!-- <mtabbarVue v-if="detect == 'h5'" /> -->
    <router-view v-if="detect == 'h5'" />
    <!-- <MbottomVue v-if="detect == 'h5'" /> -->
    <MtopVue v-if="detect == 'h5' && distanceToTop > 1000"></MtopVue>
  </div>
</template>

<script>
import Tabbar from "@/components/tabbar.vue";
import bottom from "@/components/bottom.vue";
// import mtabbarVue from "@/components/Mtabbar.vue";
// import MbottomVue from "@/components/mcom/Mbottom.vue";
import MtopVue from "@/components/mcom/Mtop.vue";
export default {
  components: {
    Tabbar,
    bottom,
    // mtabbarVue,
    // MbottomVue,
    MtopVue,
  },
  data() {
    return {
      browserWidth: window.innerWidth,
      detect: null,
      distanceToTop: 0,
    };
  },

  methods: {
    handleResize() {
      this.browserWidth = window.innerWidth;
    },
    //监听打开该项目的系统
    detectDeviceType() {
      const machineType =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? "Mobile"
          : "Desktop";
      // console.log("isH5-machineType", machineType);
      if (machineType === "Mobile") {
        // console.log("isH5-移动端");
        return "h5";
      } else {
        // console.log("isH5-Pc端");
        return "pc";
      }
    },

    /**
     * 顶部
     */
    updateDistanceToTop() {
      this.distanceToTop =
        window.pageYOffset || document.documentElement.scrollTop;
      // console.log(this.distanceToTop);
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize); // 页面宽度
    window.addEventListener("scroll", this.updateDistanceToTop);
  },
  created() {
    this.detectDeviceType();
    this.detect = this.detectDeviceType();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.updateDistanceToTop);
  },
};
</script>

<style lang="less">
#app {
  box-sizing: border-box;
  // width: 1920px;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.nowidthx {
  overflow-x: hidden;
}
</style>

<template>
  <div id="Mnews">
    <div class="tit tac">公司动态</div>
    <div class="box">
      <div
        class="box-li flex JCspaceBetween"
        v-for="(item, index) in dongtai"
        :key="index"
        @click="goDetal(item)"
      >
        <img :src="item.icon" class="box-li-icon" alt="" />
        <div class="box-li-right">
          <div class="box-li-right-tit textOne">{{ item.name }}</div>
          <div class="box-li-right-text textTwo3">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="more flex ALcenter JCcenter">
      <div>更多</div>
      <img class="more-icon" src="../../assets/m/more1.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dongtai: [
        {
          id: 1,
          name: "云茗科技成立一周年",
          text: "七月流火，在这星光璀璨的夜晚，我们欢聚一堂，共庆云茗一周年华诞。俗话说，“一年好景君须记，最是橙黄橘绿时“。一年前，我们带着梦想和希望，扬帆起航；一年后，我们满载成就与荣耀，共襄盛举。今晚，让我们以这一年的奋斗为起点，激发我们的潜力，点燃我们的激情，向着更高的目标，勇往直前！",
          time: "2024.7.19",
          icon: require("@/assets/bg/dongtai4.png"),
        },
        {
          id: 1,
          name: "创新发展 智驱未来｜云茗科技与墨非天时签订战略合作协议",
          text: "5月10日下午，浙江云茗科技股份有限公司（以下简称“云茗科技”）与墨非天时布业（杭州）有限公司（以下简称“墨非天时”）正式达成战略合作。在签约仪式现场云茗科技总经理钟总，墨非天时总经理周总代表各自企业签署了战略合作协议。...",
          time: "2024.05.10",
          icon: require("@/assets/bg/dongtai1.png"),
        },
        {
          id: 1,
          name: "开工大吉 | 华章新启，共赴新征程！",
          text: "新年新气象，新年新作为，2024年2月19日，甲辰年正月初十，云茗科技开工第一天，满怀对新年的无限热忱和期待从热闹喜庆的假期模式迅速切换到奋进忙碌的工作状态，只争朝夕的干事热情昂扬向上的奋进姿态全身心投入到公司的各项工作中开启崭新一年的奋斗，以满怀的信心迎接新的机遇和挑战。",
          time: "2024.2.19",
          icon: require("@/assets/bg/dongtai2.png"),
        },
        {
          id: 1,
          name: "年会盛典 | 云茗科技2023年会盛典顺利召开！",
          text: "杭州云茗科技有限公司2023年会盛典顺利召开，云茗科技公司各位领导、各部门负责人及全体员工出席会议，大会以“同心致远 共筑云茗”为主题，会上回顾总结了2023年度公司的发展情况，同时部署规划2024年经营目标，吹响新的集结号。",
          time: "2024.1.18",
          icon: require("@/assets/bg/dongtai3.png"),
        },
      ],
    };
  },

  methods: {
    goDetal() {
      this.$router.push("/MnewsDetal");
    },
  }
};
</script>

<style lang="less" scoped>
#Mnews {
  width: 750px;
  .tit {
    width: 750px;
    height: 126px;
    line-height: 126px;
    font-size: 36px;
    color: #353c58;
    font-weight: 600;
  }

  .box {
    width: 702px;
    margin: 0 24px;
    .box-li {
      width: 702px;
      border-bottom: 2px solid #dfe8ee;
      padding: 32px 0;
      .box-li-icon {
        width: 292px;
        height: 220px;
      }

      .box-li-right {
        width: 386px;
        .box-li-right-tit {
          width: 386px;
          height: 54px;
          line-height: 54px;
          font-weight: 600;
          font-size: 30px;
          color: #353c58;
        }

        .box-li-right-text {
          width: 386px;
          height: 120px;
          font-size: 26px;
          color: #64698b;
          line-height: 40px;
        }
      }
    }
    .box-li:last-child {
      border: 0;
    }
    .box-li:first-child {
        padding-top: 0;
    }
  }
  .more {
    width: 750px;
    height: 106px;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    .more-icon {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
<template>
  <div id="Manli">
    <div class="title fw6 hui353 tac">经典案例</div>

    <div
      class="box"
      v-for="(item, index) in jingdiananlilist[0].child"
      :key="index"
      v-show="index < 2"
      :class="index == 1?'boxbot':''"
    >
      <img class="box-icon" :src="item.icon" alt="" />
      <div class="box-body">
        <div class="box-body-tit">{{ item.title }}</div>
        <div class="box-li-text textTwo3">{{ item.text }}</div>
        <div class="box-li-tagbox flex">
          <div
            class="box-li-tagbox-tag"
            v-for="(item2, index2) in item.tag"
            :key="index2"
          >
            {{ item2 }}
          </div>
        </div>
      </div>
    </div>

    <div class="more flex ALcenter JCcenter">
      <div>更多</div>
      <img class="more-icon" src="../../assets/m/more1.png" alt="" />
    </div>
  </div>
</template>

<script>
import { jingdiananlilist } from "@/store/datat.js";

export default {
  data() {
    return {
      jingdiananlilist,
    };
  },
};
</script>

<style lang="less" scoped>
#Manli {
  width: 750px;
  background: #f2f9ff;
  .title {
    width: 750px;
    height: 126px;
    line-height: 126px;
    font-size: 36px;
  }
  .more {
    width: 750px;
    height: 106px;
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    .more-icon {
      width: 24px;
      height: 24px;
    }
  }

  .box {
    width: 698px;
    height: 648px;
    margin: 0 24px 24px;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    border: 2px solid #dfe8ee;
    overflow: hidden;
    .box-icon {
      width: 698px;
      height: 392px;
    }

    .box-body {
      width: 638px;
      margin: 0 30px;
      .box-body-tit {
        width: 638px;
        height: 82px;
        line-height: 82px;
        font-weight: 500;
        font-size: 30px;
        color: #353c58;
      }

      .box-li-text {
        width: 638px;
        height: 84px;
        line-height: 42px;
        font-size: 26px;
        color: #64698b;
        margin-bottom: 12px;
      }

      .box-li-tagbox {
        width: 638px;
        height: 36px;
        .box-li-tagbox-tag {
          padding: 0 16px;
          height: 36px;
          line-height: 36px;
          background: rgba(3, 142, 242, 0.1);
          font-size: 20px;
          color: #038ef2;
          margin-right: 20px;
          border-radius: 4px;
        }

        .box-li-tagbox-tag:nth-child(2n) {
          padding: 0 16px;
          height: 36px;
          line-height: 36px;
          background: rgba(255, 114, 34, 0.1);
          font-size: 20px;
          color: #ff7222;
          margin-right: 20px;
          border-radius: 4px;
        }
      }
    }
  }
  .boxbot {
    margin-bottom: 0;
    
  }
  .box:last-child {
    margin-bottom: 0;
  }
}
</style>